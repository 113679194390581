/* --------------------------------------------------------------
IMPORTS
----------------------------------------------------------------- */

@import 'bootstrap.css';
@import 'swiper.min.css';
@import 'calendar.css';
@import 'library.less';

/* --------------------------------------------------------------
THEME DEFAULT STYLES
----------------------------------------------------------------- */

body {background:#f8f8f8; font-size:0.75em;}

main {overflow:hidden; width:100%; position:relative;}


/* --------------------------------------------------------------
HEADER
----------------------------------------------------------------- */

body > header {

	.diagonalMenu {position:fixed; top:0; left:-333px; width:400px; height:1400px; background:#171717; overflow: hidden; .skewX(); z-index:2; box-shadow:0 2px 15px rgba(0,0,0,0.4)}
	.diagonalMenu:before {content:" "; display:block; position:absolute; width:1px; height:60%; right:0; bottom:0; background:@verde;}
	.diagonalMenu:after {content:" "; display:block; position:absolute; width:1px; height:60%; right:8px; top:0; background:rgba(255,255,255,0.2);}
	
	h1 {position:fixed; width:270px; padding:60px 0 0 30px; z-index:6;}

	.menu-principal-container {position:fixed; width:270px; z-index:4; padding:100px 0 0 30px;}

	#menu-principal {

		padding-top:60px;
		
		> li {margin-bottom:1px; position:relative; z-index:0; width:200px;}
		> li > a {display:block; height:40px; border-left:3px solid #2f2f2f; padding-left:13px; .fira(15px,300,40px,#c8c8c8,normal,none); .transition();}
		> li > a:hover {border-left:3px solid @verde; color:#fff;}

		> li.menu-item-type-custom {z-index:1; width:290px;}
		> li.menu-item-type-custom:hover > a {background:@verde; color:#000; border-left:3px solid @verde;}

		ul.sub-menu {

			position:absolute; background:@verde; width:0; left:157px; top:0; z-index:0; box-shadow:inset 3px 0 6px rgba(0,0,0,0.4); .transition(); overflow:hidden; .skewX();

			li {border-bottom:1px solid rgba(255,255,255,0.2); width:275px;}
			li a {height:30px; .fira(14px,400,30px,#000,normal,none); padding-left:20px; display:block; .transition();}
			li:hover a {background:rgba(255,255,255,0.3);}

		}
		
		> li.menu-item-type-custom:hover ul.sub-menu {width:275px;}

		> li.current-menu-item > a {border-left:3px solid @verde; color:@verde; font-size:20px;}
		> li.current-menu-ancestor > a {border-left:3px solid @verde; color:@verde; font-size:20px;}
		> li.current-menu-ancestor li.current-menu-item a {background:rgba(0,0,0,0.5); color:#fff}

	}

	.actions {

		position:fixed; top:15px; right:25px; z-index:6; padding:0 0 0 0; display:none; width:70px;

		a {display:block; height:70px; position:absolute; .fira(13px,300,40px,#fff,normal,uppercase); opacity:0; z-index:0; .transition();}
		a i {display:inline-block; width:70px; height:70px; line-height:70px; text-align:center; font-size:22px; background:#333; vertical-align:middle; margin-left:10px; border:1px solid @verde;}

		a.activated {opacity:1; z-index:1;}

	}

	@media (max-width : 1440px)
	{

		.diagonalMenu {left:0; top:-25px; width:100%; height:100px; .skewY(-2deg); box-shadow:0 2px 15px rgba(0,0,0,0.4); z-index:5;}
		.diagonalMenu:before {height:600px; transform:rotate(90.2deg); left:293px; bottom:inherit; background:#a6ce39; top:-202px;}
		.diagonalMenu:after {height:1000px; transform:rotate(90.2deg); right:497px; bottom:inherit; background:rgba(255,255,255,0.2); top:-412px;}

		h1 {padding:20px 0 0 30px;}
		h1 img {max-width:100%;}

		.menu-principal-container {z-index:4; left:inherit; right:-280px; top:0; height:100%; .skewX(0deg); padding:0; overflow:auto; .transition(); background:@verde; width:280px;}
		.menu-principal-container.opened {right:0;}

		#menu-principal {
			
			padding:110px 0 0 0; width:100%;

			li {margin-bottom:0; width:inherit; border-bottom:1px solid rgba(255,255,255,0.2);}
			li a {border-left:0; padding:0 20px 0 0; height:36px; .fira(15px,400,36px,#000,normal,none); text-align:right;}
			li > a:hover {color:#fff; background:#333; border:0;}

			li.has-submenu {width:inherit; padding:0;}
			li.has-submenu > a {border:0 !important; position: relative;}
			li.has-submenu > a:before {.fontaws("\f078",13px,#333); position:absolute; left:15px;}
			li.has-submenu > a.opened:before {.rotate(180deg)}
			li.has-submenu:hover > a {color:#fff; background:#333; border:0;}
			li.has-submenu:hover > a:before {color:#fff}

			ul.sub-menu {

				position:relative; background:rgba(255,255,255,0.1); width:auto; left:inherit; top:inherit; box-shadow:none; transform:none; height:0;

				li {border-bottom:1px solid rgba(255,255,255,0.2); width:auto;}
				li a {height:36px; .fira(15px,400,36px,#000,normal,none); padding-left:20px; display:block; .transition(); border:0 !important;}
				li:hover a {background:rgba(255,255,255,0.3); border:0 !important;}

			}
			
			li.menu-item-type-custom:hover ul.sub-menu {width:inherit;}

			> li.current-menu-item > a {color:#fff; background:#333; font-size:16px; border:0;}
			> li.current-menu-ancestor > a {color:#fff !important; background:#333 !important; font-size:16px; border:0 !important;}
			> li.current-menu-ancestor > a:before {color:#fff;}
			> li.current-menu-ancestor ul.sub-menu li.current-menu-item a {background:rgba(0,0,0,0.5); color:#fff; border:0 !important;}

		}

		.actions {display:block;}

	}
	@media (max-width : 1280px)
	{
		h1 {width:150px; padding:12px 0 0 20px;}
	}

}

body.post_cursos-template-default > header #menu-principal > li#menu-item-17 > a {border-left:3px solid @verde; color:@verde; font-size:20px;}


/* --------------------------------------------------------------
BANNERS
----------------------------------------------------------------- */

/* BANNER HOME
-----------------------------*/

#bannerHome {

	height:100%; width:100%;

	.imagem {width:100%; height:100%; position:relative; overflow:hidden; z-index:0;}

	.swiper-container {position:relative; width:100%; height:100%; z-index:0;}
	.swiper-slide {.bgCover(#121212);}

	.dgn {position:absolute; height:101%; background:rgba(0,0,0,0.5); .skewX(); .transition(); top:0; left:0;}
	.dgn:after {content:" "; display:block; position:absolute; width:1px; background:@verde; right:0;}

	.diagonal-1 {width:63%; z-index:2;}
	.diagonal-1:after {height:70%; bottom:0;}
	
	.diagonal-2 {width:90%; z-index:1;}
	.diagonal-2:after {height:60%; top:0;}

	.titleBox {
		
		position:absolute; z-index:1; bottom:100px; left:50%; width:600px; .skewX(); margin-left:-520px;

		h2 {.uni(3.4vw,300,3.7vw,#fff,normal,uppercase);}

		h3 {.fira(17px,300,20px,#c5c5c5,normal,uppercase); padding-top:20px;}

	}

	&.internas {

		height:600px; position:relative;

		.titleBox {

			bottom:40px; z-index:3; left:inherit; padding-left:15px; top:inherit;
			
			h2 {.uni(60px,300,60px,#fff,normal,uppercase);}

			h3 {.fira(30px,300,30px,#c5c5c5,normal,uppercase); padding-top:10px;}

			.mobilePos {transform:none;}

		}

		.container, .row, .bootstrap {position:relative; height:100%;}
	
	}

	@media (max-width : 1799px)
	{
		.titleBox {margin-left:-433px;}
	}
	@media (max-width : 1680px)
	{
		.titleBox {margin-left:-436px; width:500px;}
	}
	@media (max-width : 1440px)
	{
		.titleBox {margin-left:-564px; transform:none; padding-left:0; width:400px}

		&.internas .titleBox {padding-left:0}
	}
	@media (max-width : 1366px)
	{
		& {height:700px;}
		.titleBox {bottom:0; top:650px;}
		.titleBox .mobilePos {transform:translateY(-100%);}
	}
	@media (max-width : 1170px)
	{
		.titleBox {margin-left:-470px}
	}
	@media (max-width : 1024px)
	{
		& {height:600px;}
		.titleBox {left:60px; top:550px; margin:0}
		.titleBox h2 {font-size:40px; line-height:42px;}
		.titleBox .mobilePos {transform:translateY(-100%);}
		&.internas {height:500px;}
	}
	@media (max-width : 992px)
	{
		& {height:480px;}
		.dgn {left:-180px;}
		.diagonal-1 {width:85%;}
		.diagonal-2 {width:120%;}
		.titleBox {left:35px; top:440px; width:400px;}
	}
	@media (max-width : 740px)
	{
		& {height:100%;}
		.titleBox {left:50px; top:90%;}
		&.internas {height:400px;}
	}
	@media (max-width : 680px)
	{
		.titleBox {width:230px}
		.titleBox h2 {font-size:26px; line-height:30px;}
		.titleBox h3 {font-size:14px; line-height:16px; padding-top:10px;}
		&.internas {height:360px;}
		&.internas .titleBox {width:300px}
	}
	@media (max-width : 480px)
	{
		& {height:360px;}
		.titleBox {left:20px; top:340px;}
		.diagonal-1 {width:123%}
		.diagonal-2 {width:149%}
		&.internas {height:400px;}
	}
	@media (max-width : 380px)
	{
		& {height:360px;}
		.titleBox {top:335px; width:230px; left:20px}
		.titleBox h2 {font-size:26px; line-height:30px;}
		.titleBox h3 {font-size:14px; line-height:16px; padding-top:10px;}
		.diagonal-1 {width:133%}
		.diagonal-2 {width:159%}
		&.internas {height:360px;}
	}

}


/* --------------------------------------------------------------
HOME
----------------------------------------------------------------- */

main.home {
	
	.diagonal {bottom:0; height:2341px}

	.dgn-right {right:21px}

	@media (max-width : 1440px)
	{
	  .dgn-right {right:2.3%;}
	}
	@media (max-width : 1024px)
	{
	  .dgn-right {right:-9%;}
	}
	@media (max-width : 992px)
	{
	  .dgn-right {right:-19%;}
	}
	@media (max-width : 740px)
	{
	  .dgn-right {display:none;}
	}

}

#intro {

	overflow:hidden; padding-left:200px;

	.container {background:url(../images/bg-faixas.png) no-repeat right top; background-size:auto 100%; padding-top:60px; padding-bottom:60px;}

	h2 {padding-bottom:10px; font-size:38px; line-height:38px;}

	p {.fira(28px,300,28px,@azul,italic,none); padding:20px 0 0 0;}

	@media (max-width : 1440px)
	{
		& {text-align:left; padding:0;}
	}
	@media (max-width : 1024px){
		h2 {font-size:28px; line-height:28px;}
		p {font-size:17px; line-height:18px;}
	}
	@media (max-width : 992px)
	{
		.container {background:url(../images/bg-faixas.png) no-repeat right -100px top 0; background-size:auto 100%; width:100%; padding-left:15px; padding-right:15px;}
		p {font-size:20px; line-height:24px;}
	}
	@media (max-width : 740px)
	{
		p {font-size:18px; line-height:22px; padding:0 0 20px 0; width:100%; float:none;}
	}
	@media (max-width : 540px)
	{
		.container {background:none; padding-top:40px; padding-bottom:40px;}
	}

}

#servicosHome {

	position:relative; width:100%; height:700px; overflow:hidden;

	#muteYouTubeVideoPlayer {width:100%; position:relative; z-index:0; .bgCover(); background-image:url(../videos/img-thumb-video.jpg);}

	.grid {position:absolute; width:100%; height:100%; z-index:1; top:0; left:0; background:url(../images/grid.png);}
	
	.servicos {

		height:100%; background:rgba(18,18,18,0.8); padding:0; position:absolute; height:100%; right:0; width:600px; transform:translateX(33%) rotate(0deg) skewX(-20.2deg);

		.header {padding:50px 20px 45px 20px; width:300px;}

		h2 {.fira(28px,300,30px,#fff,normal,none); padding-bottom:10px}

		p {.fira(14px,400,16px,#fff,normal,none);}

		li {padding-bottom:1px; border-bottom:1px solid rgba(255,255,255,0.2);}
		li:first-child {padding-top:1px; border-top:1px solid rgba(255,255,255,0.2);}
		li a {display:block; height:40px; .fira(16px,400,40px,#fff,normal,none); padding:0 20px; border-left:3px solid @verde; .transition(200ms);}
		li a:hover {background:@verde; color:#000}

	}
	@media (max-width : 1440px)
	{
		& {height:650px}
	}
	@media (max-width : 1024px)
	{
		& {height:540px}
		.servicos {right:-90px;}
		.servicos .header {padding:20px 0 20px 20px; width:220px;}
		.servicos h2 {font-size:24px; line-height:26px;}
		.servicos li a {height:34px; line-height:34px}
	}
	@media (max-width : 992px)
	{
		& {height:490px}
		.servicos {right:-110px;}
		.servicos li a {height:30px; line-height:30px}
	}
	@media (max-width : 380px)
	{
		.servicos {right:-140px;}
		.servicos .header {width:190px;}
		.servicos p {font-size:13px; line-height:16px;}
	}

}

#personal {
	
	padding:80px 0 80px 200px; background:#fff;

	.container {position:relative; z-index:1;}

	h2 {.fira(30px,300,36px,#000,normal,none); padding-bottom:15px;}

	p {.fira(20px,400,26px,@azul,normal,none);}
	p strong {display:block; padding-top:15px; font-weight:500; color:#000}
	
	a {display:block; width:100%; height:90px; border-right:1px solid rgba(255,255,255,0.2); .fira(24px,700,90px,#333333,normal,none);}
	a i {display:inline-block; width:90px; height:90px; color:#fff; text-align:center; line-height:90px; font-size:30px; border-right:1px solid rgba(255,255,255,0.2); margin-right:25px;}
	a.personal {margin-bottom:5px; background:@verde;}
	a.run {background:@azul; color:#fff;}

	.faixas {
		
		position:absolute; width:~"calc(((100% - 1338px) / 2) + 15px)"; right:0; z-index:1;

		div.verde {height:90px; background:@verde; margin-bottom:5px;}
		div.azul {height:90px; background:@azul}

	}
	
	@media (max-width : 1440px)
	{
		& {padding-left:0;}
		.faixas {width:200px;}
	}
	@media (max-width : 1440px)
	{
		.faixas {width:200px;}
	}
	@media (max-width : 992px)
	{
		& {padding:60px 0; position:relative;}
		h2 {font-size:26px; line-height:30px;}
		p {font-size:16px; line-height:22px;}
		a {font-size:20px; height:70px; line-height:70px; border:0}
		a i {width:70px; height:70px; line-height:70px;}
		.text {padding-bottom:40px;}
		.faixas {width:100px; bottom:60px}
		.faixas div {height:70px !important;}
	}

}

#cursosHome {

	padding-left:200px;

	@media (max-width : 1440px)
	{
		& {padding:0;}
	}

}

.cursosList {
	
	padding-top:80px; padding-bottom:80px; text-align:center; overflow: hidden;

	&:not(.home) h2 {.fira(40px,500,40px,@azul,normal,none); padding-bottom:30px; text-align:left;}

	ul {width:~"calc(100% + 10px)"; position:relative; left:-20px}
	ul li {
		
		display:inline-block; padding:0 10px 30px 10px; width:~"calc(33.33% - 8px)"; vertical-align:top; text-align:left;

		a.figure {display:block; width:100%; height:200px; .bgCover();}

		span {display:block; .fira(14px,500,14px,#757575,normal,none); padding-top:15px;}

		h1 {padding-top:10px;}
		h1 a {.fira(17px,500,20px,#333,normal,none); height:60px;}

	}

	@media (max-width : 1440px)
	{
		ul {left:0}
		ul li h1 a {font-size:16px; line-height:20px; padding-right:5px;}
	}
	@media (max-width : 1280px)
	{	
		ul {left:-20px; width:~"calc(100% + 20px)";}
		&.home ul {left:-20px; width:~"calc(100% + 50px)";}
	}
	@media (max-width : 1024px)
	{
		& {padding-top:60px; padding-bottom:60px;}
		ul {left:0; width:~"calc(100% + 10px)";}
		&.home ul {left:0; width:~"calc(100% + 10px)";}
		ul li {width:33%; height:290px; display:block; float:left;}
		ul li a.figure {height:170px;}
	}
	@media (max-width : 992px)
	{
		ul li {height:248px;}
		ul li a.figure {height:120px;}
		ul li h1 a {font-size:15px; line-height:18px; display:block;}
	}
	@media (max-width : 540px)
	{
		ul li {width:50%; height:250px;}
		ul li a.figure {height:140px;}
	}
	@media (max-width : 480px)
	{
		ul {width:100%;}
		&.home ul {width:100%;}
		ul li {width:100%; height:auto; padding-bottom:20px}
		ul li a.figure {height:210px;}
	}

}


/* --------------------------------------------------------------
CENTRO DE AVALIAÇÃO
----------------------------------------------------------------- */

main.centroAvaliacao {
	
	.diagonal {bottom:0;}

	.dgn-right {right:-273px}

	.dgn-left {.skewX(-20.2deg); left:-1078px; top:0; display:none;}
	
	@media (max-width : 1440px)
	{
		.dgn-left {display:block;}
	}
	@media (max-width : 1366px)
	{
		.dgn-left {left:-1126px;}
	}
	@media (max-width : 1024px)
	{
		.dgn-left {left:-1188px;}
	}

}

#solucoes {
	
	background:@verdeEscuro; padding:50px 0; padding-left:200px; overflow:hidden; z-index:1; position:relative;

	h2 {.fira(38px,300,40px,#fff,normal,none); padding-bottom:30px;}

	ul li {
		
		float:left; height:45px; border-left:1px solid rgba(255,255,255,0.2); padding-left:20px; margin-left:20px;

		img {float:left; margin-right:15px; height:100%; width:auto;}

		div.content {height:45px; position:relative; float:left; max-width:180px;}
		div.middle {.fira(18px,400,20px,#fff,normal,none);}

		&:first-child {border:0; padding-left:0; margin-left:0}

	}

	@media (max-width : 1800px)
	{
		ul li {
			
			height:40px; padding-left:14px; margin-left:20px;
			
			&:first-child {margin-left:0;}
			
			div.content {height:40px;}
			div.middle {font-size:16px; line-height:18px;}
		
		}
	}
	@media (max-width : 1440px)
	{
		& {padding:30px 0;}
	}
	@media (max-width : 1024px)
	{
		
		& {display:none;}

	}

}

#avaliacoes {
	
	padding:80px 0; padding-left:200px; position:relative; z-index:1;

	article {
	
		border-left:1px solid @verde;

		.loadContent {padding:3px 0 3px 15px;}

		iframe, .propBox {width:100%; opacity:0; .bgCover(#333,inherit); margin:30px 0 35px 0;}

		h1 {.uni(44px,400,44px,#000,normal,none);}

		p {.fira(28px,400,34px,@azul,normal,none); padding-bottom:20px;}

		ul:not(.imageList) {padding:20px; margin:10px 0 30px 0; background:@azul;}
		ul:not(.imageList) li {.fira(20px,400,30px,#fff,normal,none); padding:5px 0 5px 12px; position:relative;}
		ul:not(.imageList) li:before {content:"•"; position:absolute; left:0; top:4px; color:#fff; font-size:26px;}

		.footer a {

			display:inline-block; height:60px; background:#9fc636; color:#fff; .fira(18px,500,60px,#fff,normal,uppercase); .transition(); cursor:pointer; 

			i {display:inline-block; vertical-align:middle; height:60px; line-height:60px; font-size:28px; text-align:center;}
			i.fa-calendar {width:60px; border-right:1px solid rgba(255,255,255,0.2); margin-right:20px;}
			i.fa-angle-right {width:60px;}

			&:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}
		
		}

		.gallery {
		
			border-top:1px solid rgba(0,0,0,0.1); margin-top:40px; padding-top:40px;

			li {display:inline-block; width:~"calc(25% - 4px)"; height:160px; margin-right:4px;}
			li a {display:block; width:100%; height:100%; .bgCover(); .transition();}
			li a:hover {opacity:0.7;}

		}

	}

	aside {
		
		h3 {.fira(30px,300,30px,@textos,normal,none); padding:8px 0 36px 0;}

		ul li {
			
			border-bottom:1px solid rgba(0,0,0,0.1);

			&:first-child {border-top:1px solid rgba(0,0,0,0.1);}

			a {display:block; height:50px; padding:0 0 0 15px; .fira(16px,300,50px,@links,normal,none); border-left:3px solid rgba(0,0,0,0.1); .transition();}
			a:hover {background:rgba(0,0,0,0.1)}

			&.current-menu-item a {border-left:3px solid @verdeEscuro; background:@verdeEscuro; color:#fff; font-weight:500;}

		}

	}

	@media (max-width : 1440px)
	{
		& {padding-left:0;}
	}
	@media (max-width : 1024px)
	{

		article {
			
			h1 {font-size:34px;}

			p {font-size:24px; line-height:30px;}

			ul li {font-size:16px; line-height:26px;}

			.gallery li {height:120px;}

		}
		
		aside {

			h3 {font-size:24px; line-height:24px; padding:13px 0 36px 0;}

			ul li a {height:auto; padding:10px 0 10px 10px; font-size:14px; line-height:16px;}

		}

	}
	@media (max-width : 992px)
	{
		
		article {border:0; border-bottom:1px solid @verde; padding-bottom:30px;}
		article .loadContent {padding:0;}
		article .gallery li {height:140px;}

		aside {padding-top:30px;}
	
	}
	@media (max-width : 480px)
	{
		article {

			p {font-size:20px; line-height:24px;}
			
			.footer a {

				height:50px; font-size:16px; line-height:50px;

				i {height:50px; line-height:50px; font-size:20px;}
				i.fa-calendar {width:50px; margin-right:10px;}
				i.fa-angle-right {width:40px;}
				
			}

			.gallery li {height:140px; width:~"calc(50% - 4px)";}

		}
	}

}


/* --------------------------------------------------------------
SERVIÇOS
----------------------------------------------------------------- */

#servicos {

	padding:20px 0 80px 200px;

	li {position:relative; overflow:hidden; padding:50px 0 30px 0; border-bottom:3px solid @verde; clear:both;}

	h3 {.uni(32px,400,32px,#000,normal,none); padding-bottom:30px; font-weight:bold;}

	.text {border-right:1px solid rgba(0,0,0,0.1); height:70px; position:relative;}
	.text p {.fira(17px,300,24px,@textos,normal,none); padding-right:120px; .middle();}
	.text p strong {color:@azul; font-weight:700;} 

	.button {padding-left:20px;}
	.button button {width:100%; height:70px; display:block; background:@verde; .fira(18px,700,70px,#fff,normal,uppercase); padding:0 0 0 18px; .transition(); cursor:pointer}
	.button button i {width:70px; height:70px; vertical-align:middle; text-align:center; line-height:70px; border-left:1px solid rgba(255,255,255,0.2); float:right; font-size:26px}
	.button button:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

	@media (max-width : 1440px)
	{
		& {padding-left:0;}
	}
	@media (max-width : 1366px)
	{
		
		.text {height:auto; min-height:70px;}
		.text p {.middleOff(); padding-right:40px}

		.button a {font-size:16px; padding:0 0 0 15px;}
		.button a i {width:50px;}

	}
	@media (max-width : 1024px)
	{

		.text p {font-size:15px; padding-right:20px;}

		.button a {font-size:18px; padding:0 0 0 22px;}
		.button a i {width:70px;}

	}
	@media (max-width : 992px)
	{

		& {padding:0 0 50px 0;}
		
		h3 {padding:0 0 20px 0; font-size:28px;}

		.text {border:none; padding:0;}
		.text p {padding-right:0;}

		.button {padding:20px 0 0 0;}
		.button a {font-size:18px; padding:0 0 0 20px; width:auto; height:60px; line-height:60px; display:inline-block; position:relative; left:-8px;}
		.button a i {width:60px; float:none; margin-left:20px; height:60px; line-height:60px;}

	}

}

/* --------------------------------------------------------------
TEEN TEST IN GROUP
----------------------------------------------------------------- */

main.mainTennTest {

	.diagonal {bottom:inherit; top:0; z-index:0;}
	
	.dgn-right {right:10.1%;}
	
	@media (max-width : 1440px)
	{
		.dgn-right {right:-1.8%;}
	}
	@media (max-width : 1280px)
	{
		.dgn-right {right:-4.8%;}
	}
	@media (max-width : 1024px)
	{
		.diagonal {bottom:0; top:inherit;}
		.dgn-right {right:-310px;}
	}

}

#teenTest {

	padding:80px 0 80px 200px; position:relative; z-index:1;

	h1 {padding-left:0; padding-bottom:40px;}

	a {height:60px; display:inline-block; background:@verdeEscuro; .fira(18px,700,60px,#fff,normal,uppercase); .transition();}
	a i {width:60px; height:60px; text-align:center; line-height:60px; font-size:26px; vertical-align:middle;}
	a i.fa-user-circle {border-right:1px solid rgba(255,255,255,0.2); margin-right:26px;}
	a i.fa-angle-right {margin-left:15px; position:relative; top:-2px}
	a:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

	button {height:60px; display:inline-block; background:@azul;.fira(18px,700,60px,#fff,normal,uppercase); .transition(); padding-left:28px; cursor:pointer}
	button i {width:60px; height:60px; text-align:center; line-height:60px; font-size:26px; vertical-align:middle;}
	button i.fa-user-circle {border-right:1px solid rgba(255,255,255,0.2); margin-right:26px;}
	button i.fa-angle-right {margin-left:15px; position:relative; top:-2px}
	button:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

	.text {padding-left:0;}
	.text p {.fira(19px,300,28px,@textos,normal,none); padding-right:25px; padding-bottom:20px;}
	.text p strong {color:@azul; font-weight:700;}

	.blueBtn {padding-top:20px;}

	.avaliacao {border-left:1px solid @verde;}

	ul {padding-left:15px;}

	li {padding:13px 0 13px 10px; .fira(19px,300,22px,@textos,normal,none); position:relative;}
	li:before {content:"•"; color:@verdeEscuro; position:absolute; left:0; font-size:22px}
	li:first-child {padding-top:0;}

	.greenBtn {padding:40px 0 0 15px}

	@media (max-width : 1440px)
	{
		& {padding-left:0;}
	}
	@media (max-width : 992px)
	{

		& {padding:60px 0;}

		.text {padding-bottom:40px;}
		.text p {padding-right:0;}
		
		.avaliacao {border:0; border-top:1px solid @verde; padding-top:40px; padding-left:0}

		ul, .greenBtn {padding-left:0;}

	}

}

main.mainTennTest .banner {

	position:relative; z-index:1; width:100%; height:690px; .bgCover();

	@media (max-width : 1440px)
	{
		& {height:500px;}
	}

}


/* --------------------------------------------------------------
PERSONAL
----------------------------------------------------------------- */

main.mainPersonal {

	.diagonal {bottom:inherit; top:0; z-index:0;}
	
	.dgn-right {right:10.1%;}
	
	@media (max-width : 1440px)
	{
		.dgn-right {right:-1.8%;}
	}
	@media (max-width : 1280px)
	{
		.dgn-right {right:-4.8%;}
	}
	@media (max-width : 1024px)
	{
		.diagonal {bottom:0; top:inherit;}
		.dgn-right {right:-310px;}
	}

}

.personalPage {
	
	padding:60px 0 50px 0;

	article {

		overflow:hidden; z-index:1; position:relative;
		
		p {.fira(16px,400,24px,@textos,normal,none); padding-bottom:20px;}

		a.btnGreen {height:60px; display:inline-block; background:@verdeEscuro; .fira(18px,700,60px,#fff,normal,uppercase); .transition(); padding-left:20px; margin-bottom:10px}
		a.btnGreen i {width:60px; height:60px; text-align:center; line-height:60px; font-size:26px; vertical-align:middle; position:relative; top:-2px}
		a.btnGreen:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

		button {height:60px; display:inline-block; background:@verdeEscuro; .fira(18px,700,60px,#fff,normal,uppercase); .transition(); padding-left:20px; margin-bottom:10px; cursor:pointer;}
		button i {width:60px; height:60px; text-align:center; line-height:60px; font-size:26px; vertical-align:middle; position:relative; top:-2px}
		button:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

		iframe, .propBox {width:100%; opacity:0; background:rgba(0,0,0,0.2); position:relative;}

		.padding {padding-right:25px}

		.swiper-container {width:100%; height:100%; z-index:0; position:relative;}

		.swiper-slide {.bgCover();}

		.navigation a {display:block; z-index:1; width:72px; height:72px; background:@verdeEscuro; border:6px solid #f4f4f4; border-radius:100px; .middle(); position:absolute; text-align:center;}
		.navigation a i {color:#fff; font-size:40px; line-height:55px;}
		.navigation a.arrow-left {left:0; transform:translate(-40%,-50%);}
		.navigation a.arrow-right {right:0; transform:translate(40%,-50%);}

	}

	&.intro {

		background:#f4f4f4; padding:80px 0 40px 200px;

		p {.fira(20px,400,26px,@azul,normal,none); padding-bottom:30px;}
		p strong {display:inline-block; padding:10px 15px; background:@azul; color:#fff; font-weight:500;}

	}

	&.personalTraining {background:#fff; padding-left:200px;}

	&.runCoach {background:#f4f4f4; padding-left:200px;}

	@media (max-width : 1440px)
	{
		&.intro {padding-left:0}
		&.personalTraining {padding-left:0}
		&.runCoach {padding-left:0}
		article .navigation a.arrow-left {left:0; transform:translate(-25%,-50%);}
		article .navigation a.arrow-right {right:0; transform:translate(25%,-50%);}
	}
	@media (max-width : 992px)
	{
		article .texto {padding-bottom:30px}
		article .padding {padding-right:0}
	}
	@media (max-width : 740px)
	{
		& {padding:40px 0 20px 0;}
		&.intro {padding:40px 0 20px 0;}
		&.intro p {font-size:16px;}
	}

}

/* --------------------------------------------------------------
CURSOS
----------------------------------------------------------------- */

main.mainCursos {

	.diagonal {bottom:inherit; top:0; z-index:0;}
	
	.dgn-right {right:10.1%;}
	
	@media (max-width : 1440px)
	{
		.dgn-right {right:-1.8%;}
	}
	@media (max-width : 1280px)
	{
		.dgn-right {right:-4.8%;}
	}
	@media (max-width : 1024px)
	{
		.diagonal {bottom:0; top:inherit;}
		.dgn-right {right:-310px;}
	}

}

#cursosPage {

	.container {position:relative; z-index:1;}

	.intro {

		background:@verdeEscuro; padding:50px 0; padding-left:200px;

		p {.fira(32px,300,44px,#000,normal,none);}

	}

	.informacoes {
		
		padding:80px 0; padding-left:200px; background:#fff;

		li {

			padding:0 65px 50px 0;

			h3 {display:inline-block; padding:10px 15px; background:@azul; .fira(26px,400,26px,#fff,normal,none); margin-bottom:30px;}

			p, address {.fira(18px,400,26px,#000,normal,none);}

			a {color:@azul;}

			&:last-child {padding-bottom:0;}

		}

		ul:first-child {border-right:1px solid rgba(0,0,0,0.1);}
		
		ul:last-child {border:0;}
		ul:last-child li {padding:0 0 50px 35px}
		ul:last-child li p {padding-bottom:25px;}
		ul:last-child li p:last-child {padding:0}
		ul:last-child li p strong {display:block;}

	}

	.cursosList {
	
		margin-left:200px; padding-bottom:40px;
		
		ul li {width:~"calc(33.33% - 8px)"; padding:0 10px; padding-bottom:30px;}
		ul li a.figure {height:200px; .bgCover();}
		ul li h1 {height:60px;}

	}
	
	@media (max-width : 1440px)
	{
		.intro {padding-left:0;}
		.informacoes {padding-left:0;}
		.cursosList {margin-left:0;}
	}
	@media (max-width : 1024px)
	{
		.intro {

			padding:40px 0;

			p {.fira(22px,300,30px,#000,normal,none);}

		}

		.informacoes {
			
			padding:60px 0;

			li {

				padding:0 0 50px 0;

				h3 {padding:7px 12px; .fira(22px,400,22px,#fff,normal,none); margin-bottom:20px;}

			}

			ul:first-child {border:0; padding-bottom:50px;}

			ul:last-child li {padding:0 0 50px 0}
			ul:last-child li p {padding-bottom:15px;}

		}
	}
	@media (max-width : 940px){
		.cursosList ul li {height:250px;}
		.cursosList ul li a.figure {height:140px;}
	}
	@media (max-width : 540px)
	{
		.cursosList ul li {width:50%; height:250px;}
	}
	@media (max-width : 480px)
	{
		
		.intro {padding:30px 0;}
		.intro p {.fira(16px,400,22px,#000,normal,none);}

		.cursosList {
			ul {width:100%;}
			ul li {width:100%; height:auto; padding-bottom:40px}
			ul li a.figure {height:210px;}
			ul li h1 {height:auto;}
		}
		
		.informacoes {padding:40px 0;}
		.informacoes li {

			h3 {padding:5px 10px; .fira(18px,400,20px,#fff,normal,none); margin-bottom:20px;}

			p {.fira(16px,400,22px,#000,normal,none);}

		}
		.informacoes ul:last-child li:last-child {padding:0}

	}


}


/* --------------------------------------------------------------
SINGLE CURSOS
----------------------------------------------------------------- */

#bannerHome.internas {
	
	.swiper-slide {padding-left:200px;}

	@media (max-width : 1440px)
	{
		.swiper-slide {padding-left:0;}
	}

}

#bannerHome.internas .titleBox {

	left:inherit !important; margin:0 !important;

	h2 {font-size:50px; line-height:54px;}

	@media (max-width : 1440px)
	{
		h2 {font-size:45px; line-height:50px;}
	}
	@media (max-width : 1366px)
	{
		h2 {font-size:42px; line-height:46px;}
	}
	@media (max-width : 1024px)
	{
		& {width:360px;}
	}
	@media (max-width : 480px)
	{
		& {width:360px;}
		h2 {font-size:38px; line-height:42px;}
		h3 {font-size:20px; line-height:20px;}
	}
	@media (max-width : 320px)
	{
		& {width:340px;}
		h2 {font-size:30px; line-height:32px;}
		h3 {font-size:16px; line-height:18px;}
	}

}

main.mainCursoSingle {

	.diagonal {bottom:inherit; top:0; z-index:0;}
	
	.dgn-right {right:10.1%;}
	
	@media (max-width : 1440px)
	{
		.dgn-right {right:-1.8%; height: 1593px;}
	}
	@media (max-width : 1280px)
	{
		.dgn-right {right:-4.8%;}
	}
	@media (max-width : 1024px)
	{
		.dgn-right {right:-310px;}
	}

}

#cursoSingle {

	.container {position:relative; z-index:1;}

	.intro {

		background:@verdeEscuro; padding:40px 0; padding-left:200px;

		p {.fira(32px,300,44px,#000,normal,none);}

	}

	.singleListaCursos {padding-left:200px;}

	.informacoes {
		
		padding:60px 0; background:#f2f2f2; padding-left:200px;

		h3 {.fira(40px,500,40px,#000,normal,none); margin-bottom:20px;}
		h4 {.fira(18px,500,18px,@azul,normal,uppercase); padding:10px 0;}

		p {.fira(16px,500,20px,#000,normal,none); padding-bottom:20px;}

		ul {.cols(2,80px); .colsRules(1px,solid,@verde); padding-top:10px;}
		ul li {

			.fira(20px,400,22px,#000,italic,none); padding:10px 0 10px 15px; position:relative;

			&:before {content:"•"; position:absolute; left:0; top:10px; color:@verde; font-size:26px;}

		}

		button {height:60px; display:inline-block; padding-left:20px; background:@azul; .fira(16px,700,60px,#fff,normal,uppercase); padding:0 0 0 18px; .transition(); cursor:pointer}
		button i {width:60px; height:60px; vertical-align:middle; text-align:center; line-height:60px; border-left:1px solid rgba(255,255,255,0.2); float:right; font-size:26px; margin-left:20px;}
		button:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

	}

	.calendarios {

		padding:80px 0 50px 0; background:#fff; padding-left:200px;

		.wrapCalendar {float:left;}

		.calendars
		{
			margin-right:40px; margin-bottom:20px; background:#9ec33b; border-radius:5px;
			
			.calendar-hd {height:60px; padding:0; text-align:center;}
			.calendar-hd a {.fira(16px,500,60px,#fff,normal,uppercase); cursor:default; display:block; float:none;}

			.calendar-views .week li {background:#799335; .fira(14px,300,30px,#fff,normal,none); height: 30px;}

			.days li {.fira(16px,400,40px,#fff,normal,none); border-top:1px solid rgba(255,255,255,0.2); border-right:1px solid rgba(255,255,255,0.2); border-radius:0;}
			.days li.now {background:#799335}
			.days li.new {color:#799335}

		}

		a.btnQueroInscrever
		{
			height:60px; width: 280px; display:inline-block; background:@azul; .fira(16px,700,60px,#fff,normal,uppercase); padding:0 0 0 18px; .transition(); cursor:pointer; margin-bottom:30px;
			
			&:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

			i {width:60px; height:60px; vertical-align:middle; text-align:center; line-height:60px; border-left:1px solid rgba(255,255,255,0.2); float:right; font-size:26px; margin-left:20px;}
		}

		.infoCurso {
				
			float:left;

			strong {display:block; .fira(18px,500,18px,@azul,normal,uppercase);}
			p {.fira(18px,300,22px,#000,normal,none); padding-bottom:30px;}

			a {height:60px; display:inline-block; padding-left:20px; background:@azul; .fira(16px,700,60px,#fff,normal,uppercase); padding:0 0 0 18px; .transition(); cursor:pointer}
			a i {width:60px; height:60px; vertical-align:middle; text-align:center; line-height:60px; border-left:1px solid rgba(255,255,255,0.2); float:right; font-size:26px; margin-left:20px;}
			a:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

		}

	}
	
	.cancelamento {

		padding:40px 0 50px 0; background:#fff; border-top:1px solid rgba(0,0,0,0.1); padding-left:200px;
		
		h2 {.fira(18px,500,18px,@azul,normal,uppercase); padding-bottom:20px;}

		p {.fira(18px,300,22px,#000,normal,none); padding-bottom:20px;}

		li {

			.fira(14px,400,16px,#000,normal,none); padding:5px 0 5px 15px; position:relative;

			&:before {content:"•"; position:absolute; left:0; top:10px; color:@verde; font-size:26px;}

		}
		

	}
	
	@media (max-width : 1440px){
		.intro, .informacoes, .calendarios, .singleListaCursos, .cancelamento {padding-left:0;}
	}
	@media (max-width : 1366px)
	{
		.informacoes ul li {.fira(18px,400,20px,#000,normal,none);}
	}
	@media (max-width : 1024px)
	{
		.intro {padding:40px 0;}
		.intro p {.fira(22px,300,30px,#000,normal,none);}

		.informacoes ul li {.fira(16px,400,18px,#000,normal,none);}

		.calendarios {padding:60px 0 30px 0}
		.calendarios .calendars {margin-right:30px;}
	}

	@media (max-width : 740px)
	{
		.informacoes h3 {font-size:30px; line-height:30px;}
		.calendarios .calendars {margin-right:25px;}
	}
	@media (max-width : 480px)
	{
		
		.intro {padding:30px 0;}
		.intro p {.fira(16px,400,22px,#000,normal,none);}
		
		.informacoes ul {.cols(1,0); .colsRules(0,solid,@verde);}

	}


}


/* --------------------------------------------------------------
Contato
----------------------------------------------------------------- */

main.contatoPage {

	.diagonal {bottom:inherit; top:0; z-index:0;}
	
	.dgn-right {right:10.1%;}
	
	@media (max-width : 1440px)
	{
		.dgn-right {right:-1.8%;}
	}
	@media (max-width : 1280px)
	{
		.dgn-right {right:-4.8%;}
	}
	@media (max-width : 1024px)
	{
		.diagonal {bottom:0; top:inherit;}
		.dgn-right {right:-310px;}
	}
}

#contato {
	
	padding:0 0 80px 200px; position:relative; z-index:2;
	
	.intro {

		padding-top:80px; padding-bottom:60px;

		p {.fira(24px,500,28px,@azul,normal,none);}

		.alert
		{
			p 
			{
				padding: 20px;
				background-color: #fff3cd;
				border: 1px solid #ffeeba;
				display: flex; 
				flex-flow: row nowrap; 
				justify-content: flex-start; 
				align-items: flex-start; 
				width: 100%;
				.fira(18px,400,22px,#856404,normal,none);
				border-radius: 10px;

				i {font-size: 20px; width: 30px; position: relative; top: 1px;}
				span {display: block; width: ~"calc(100% - 30px)";}
			}
		}

	}

	.form 
	{
		position: relative; overflow: hidden;

		fieldset { padding-top:13px}

		&:not(.inscricao) {border-right:1px solid rgba(0,0,0,0.1);}

		@media screen and (min-width:481px)
		{		
			&.inscricao fieldset {width: ~"calc(100% + 30px)"}
		}

		p {.fira(13px,400,16px,@textos,normal,none); padding-bottom:20px;}
		p span {color:#f51d1d}

		.input
		{
			width:~"calc(100% - 30px)"; padding-bottom:25px;
			
			&.half {width:~"calc(50% - 30px)"; margin-right:30px; float:left;}

			p {padding: 0;}
		}

		label {display:block; .fira(18px,400,18px,@textos,normal,none); padding-bottom:10px;}
		label span {color:#f51d1d; font-size:14px}

		input {height:50px;}
		input:not(.submit) {width:100%; padding:0 15px; background:#fff; border:1px solid rgba(0,0,0,0.1); .fira(14px,400,50px,@azul,normal,none);}

		textarea {width:100%; padding:10px 15px; height:245px; .fira(14px,400,18px,@azul,normal,none); background:#fff; border:1px solid rgba(0,0,0,0.1);}

		select {height:50px; width:100%; padding:0 15px; background:#fff; border:1px solid rgba(0,0,0,0.1); .fira(14px,400,50px,@textos,normal,none);}
		select option {padding:15px; height: 30px;}

		div.captcha
		{
			text-align:right; padding-right:30px;

			p {display: flex; flex-flow: row-reverse nowrap; justify-content: flex-start; align-items: center;}

			input.wpcf7-submit {width:100px; display:inline-block; background:@verdeEscuro; color:#fff; cursor:pointer; .fira(18px,700,50px,#fff,normal,none); .transition();}
			input.wpcf7-submit:hover {box-shadow:0 0 10px rgba(0,0,0,0.3); background:@verde;}
		}

		.wpcf7-not-valid-tip {position: absolute}

		.wpcf7-response-output
		{
			&.alert-danger, &.alert-warning {.fira(14px,500,18px,@textos,normal,none); padding: 20px; text-align: center; background: #FFECEE; border: 2px solid #FFACB6;} 
			&.alert-success {.fira(14px,500,18px,@textos,normal,none); padding: 20px; text-align: center; background: #ECFFEF; border: 2px solid #C8ECC9;} 
		}
	}

	.sidebar {
		
		h3 {.fira(30px,300,30px,#000,normal,none); padding-bottom:15px;}
		h3 i {vertical-align:middle; width:30px; height:40px; line-height:35px; color:@azul; font-size:26px;}

		address {.fira(18px,500,24px,@textos,normal,none); padding-bottom:20px;}
		p {.fira(18px,500,24px,@textos,normal,none);}
		a.tel {color:@textos}

		a.button {height:60px; padding-right:20px; display:inline-block; background:#009846; .fira(18px,700,60px,#fff,normal,uppercase); .transition(); text-align:left;}
		a.button i {width:60px; height:60px; text-align:center; line-height:60px; font-size:30px; vertical-align:middle; position:relative; top:-2px;}
		a.button.green span {display:inline-block; font-size:24px; text-transform:none; padding-right:15px; border-right:1px solid rgba(255,255,255,0.2); margin-right:15px;}
		a.button:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

		strong {display:block; .fira(14px,500,16px,@azul,normal,none); padding-bottom:6px;}

		form {

			width:100%; padding:0; background:#fff; border:1px solid rgba(0,0,0,0.1); 

			input:not(.submit) {.fira(14px,400,50px,@azul,italic,none); width:~"calc(100% - 60px)"; height:50px; padding:0 15px;}

			input.submit {width:50px; height:50px; background:@azul url(../images/bg_submit_maps.png) center center; background-size:canvas; text-indent:-9999px; cursor:pointer;}

		}

		.infoCurso
		{	
			border-bottom:1px solid rgba(0,0,0,0.1); padding:35px 0 0 0;

			strong {display:block; .fira(18px,500,18px,@azul,normal,uppercase);}
			p {.fira(18px,300,22px,#000,normal,none); padding-bottom:30px;}
		}

		.maps {padding:35px 0; border-bottom:1px solid rgba(0,0,0,0.1);}
		.telefones {padding:40px 0; border-bottom:1px solid rgba(0,0,0,0.1);}
		.whatsapp {padding-top:40px;}

	}

	@media (max-width : 1440px)
	{
		& {padding-left:0;}
	}
	@media (max-width : 992px)
	{

		& {padding:0 0 60px 0;}

		.intro {padding-top:60px; padding-bottom:60px;}

		.form fieldset {border:none;}
		.form .input {width:100%;}

	}
	@media (max-width : 480px)
	{

		.intro {padding-top:40px; padding-bottom:40px;}

		.form {

			padding-bottom:40px;

			.input.half {width:100%; margin:0;}

			div.captcha {

				padding-right:0;

				label strong {font-size:14px;}

				input:not(.submit) {width:40px; margin:0 5px; font-size:16px; padding:0}

				input.submit {width:80px; font-size:16px;}

				img {margin:0 20px;}

			}

		}

		.sidebar .maps {border-top:1px solid rgba(0,0,0,0.1);}

	}

}

main.sucessoPage {

	.diagonal {bottom:inherit; top:0; z-index:0;}
	
	.dgn-right {right:10.1%;}
	
	@media (max-width : 1440px)
	{
		.dgn-right {right:-1.8%;}
	}
	@media (max-width : 1280px)
	{
		.dgn-right {right:-4.8%;}
	}
	@media (max-width : 1024px)
	{
		.diagonal {bottom:0; top:inherit;}
		.dgn-right {right:-310px;}
	}
}

#sucesso {

	padding:80px 0 80px 200px; z-index:2; position:relative;

	li {position:relative; overflow:hidden; padding:50px 0 30px 0; border-bottom:3px solid @verde; clear:both;}

	h3 {.uni(36px,400,36px,#009d0b,normal,none); padding-bottom:60px; font-weight:bold;}
	
	p {.fira(20px,500,24px,@textos,normal,none); padding-right:120px; .middle(); padding-bottom:20px;}
	
	a {height:60px; display:inline-block; background:@verdeEscuro; .fira(18px,700,60px,#fff,normal,uppercase); padding:0 30px; .transition(); cursor:pointer}
	a:hover {box-shadow:0 0 10px rgba(0,0,0,0.3)}

	@media (max-width : 1440px)
	{
		& {padding-left:0;}
	}
	@media (max-width : 740px)
	{
		p {padding-right:0;}
	}
	@media (max-width : 540px)
	{
		h3 {font-size:26px; line-height:28px;}
		p {font-size:18px; line-height:22px}
	}

}


/* --------------------------------------------------------------
FOOTER
----------------------------------------------------------------- */

body > footer {

	.informacoes {

		background:@azul; padding:40px 0 40px 200px; text-align:center;

		address {.fira(20px,400,24px,#fff,normal,none); padding-bottom:10px;}

		.telefones a {display:inline-block;}
		.telefones a span {.fira(18px,300,18px,#fff,normal,none);}
		.telefones strong {padding:0 10px; .fira(18px,300,18px,@cor : rgba(255,255,255,0.2),normal,none);}

		.whatsapp {
			
			padding:30px 0 20px 0; text-align:center;

			a {display:inline-block; height:50px; border:2px solid #fff; .fira(16px,700,46px,#fff,normal,uppercase); .transition(); padding-right:20px;}
			a i {width:50px; height:46px; text-align:center; line-height:46px; font-size:26px; vertical-align:middle; position:relative; top:-4px;}
			a span {display:inline-block; font-size:20px; text-transform:none; padding-right:15px; border-right:1px solid rgba(255,255,255,0.2); margin-right:15px;}
			a:hover {background:rgba(255,255,255,0.2)}

		}

		.map {padding:0 0 20px 0;}
		.map a {display:inline-block; .fira(14px,500,48px,#fff,normal,uppercase);}
		.map a i {font-size:16px; margin-right:5px}

		.social {
			
			li {display:inline-block;}
			li a {display:block; width:30px; height:30px; margin:0 4px}
			li a i {line-height:30px; color:#fff; font-size:22px;}

		}

	}

	.rodape {
		
		height:51px; background:#2f2f2f; padding-left:200px;

		p {.fira(12px,400,50px,#fff,normal,none);}

		a {display:block; float:right;}

	}
	
	@media (max-width : 1440px)
	{
		.informacoes {padding-left:0;}
		.rodape {padding-left:0;}
	}
	@media (max-width : 1024px)
	{
		.rodape {

			height:auto; text-align:center; padding:20px 0;

			p {padding:10px 0; line-height:16px; float:none; width:100%;}

			a {display:inline-block; float:none !important;}

		}
	}

}

.floatButtonsWpp
{
	position:fixed; z-index:3; display:block; right:30px; bottom:30px; text-align:center;
}

@media screen and (max-width : 992px)
{
	.floatButtonsWpp {bottom:10px; right:10px;}
	.floatButtonsWpp img {width:auto; height:60px;}
}

/* ALERTAS */

#lightbox {

	background:rgba(0,0,0,0.9); position:fixed; z-index:100000; width:100%; height:100%; top:0; left:0; display:none; opacity:0; .fira(14px,600,16px,#fff,normal,none);

	div {margin:0 auto; position:relative; width:80%; max-width:500px; top: 50%; transform:translateY(-50%); -ms-transform:translateY(-50%); -webkit-transform:translateY(-50%);}
	div p {padding:20px 40px; color:#fff; font-weight:600; font-size:14px; text-transform:uppercase; text-align:center;}
	
	a {display:block; position:absolute; top:-10px; right:-10px; height:40px; width:40px; line-height:32px; color:#fff; font-weight:bold; text-align:center; border:3px solid #fff; border-radius:30px; font-size:16px;}

	.sucesso {background:#6eaa53;}
	.sucesso a { background:#6eaa53;}

	.erro {background:#f5506b;}
	.erro a { background:#f5506b;}

}