/* --------------------------------------------------------------
IMPORTS
----------------------------------------------------------------- */
@import 'bootstrap.css';
@import 'swiper.min.css';
@import 'calendar.css';
/* --------------------------------------------------------------
RESET
----------------------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
  list-style-type: none;
  text-decoration: none;
  list-style-image: none;
  box-sizing: border-box;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
header,
section,
footer,
article,
figcaption,
hgroup,
figure,
details,
aside,
img,
nav,
time,
bloquote,
svg {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
[hidden] {
  display: none;
}
object[type="application/gas-events-uni"] {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  display: block;
  text-indent: -999999px;
}
input[type="search"],
input[type="submit"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 0;
  -webkit-border-radius: 0;
}
/* --------------------------------------------------------------
GRID SYSTEM
----------------------------------------------------------------- */
.wrap {
  width: calc(100% - 16%);
  margin: 0 8%;
  position: relative;
  clear: both;
}
.dryWrap {
  width: 96%;
  margin: 0 28px;
  position: relative;
  clear: both;
}
/* --------------------------------------------------------------
DEFAULT STYLES
----------------------------------------------------------------- */
html,
body {
  height: 100%;
}
/* FORMATING CLASSES
---------------------------- */
.clear {
  clear: both;
}
.hide {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  display: block;
  text-indent: -999999px;
}
hr {
  display: none;
}
.left {
  float: left;
}
.right {
  float: right;
}
.center {
  margin: 0 auto;
}
.middle {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.middleOff {
  top: inherit;
  transform: none;
}
.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
.textCenter {
  text-align: center;
}
img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}
img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}
img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
a img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}
a img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}
a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.padding60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.padding40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
span.bar {
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  width: 36px;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
/* SIZES */
.full_X {
  width: 100% !important;
  position: relative;
}
.full_Y {
  height: 100% !important;
  position: relative;
}
.fullSize {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}
/* BACKGROUNDS */
/* COLORS */
/* FONTS */
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWV3PuMR0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOhEADFm8hSaQTFG18FErVhsC9x-tarUfbtqA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWQXOuMR0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWU3IuMR0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'UniSansBook';
  src: url('fontes/UniSansBook.eot?#iefix') format('embedded-opentype'), url('fontes/UniSansBook.woff') format('woff'), url('fontes/UniSansBook.ttf') format('truetype'), url('fontes/UniSansBook.svg#UniSansBook') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'UniSansRegular';
  src: url('fontes/UniSansRegular.eot?#iefix') format('embedded-opentype'), url('fontes/UniSansRegular.woff') format('woff'), url('fontes/UniSansRegular.ttf') format('truetype'), url('fontes/UniSansRegular.svg#UniSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'UniSansBold';
  src: url('fontes/UniSansBold.eot?#iefix') format('embedded-opentype'), url('fontes/UniSansBold.woff') format('woff'), url('fontes/UniSansBold.ttf') format('truetype'), url('fontes/UniSansBold.svg#UniSansBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*
FIRA
Light 300, Normal 400, Medium 500, Bold 700
*/
.titulo {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 42px;
  font-weight: 300;
  line-height: 46px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
.titulo strong {
  color: #06377b;
  font-weight: 700;
}
.subtitulo {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  color: #06377b;
  font-style: normal;
  text-transform: none;
}
.uniTitBook {
  font-family: 'UniSansBook';
  font-size: 42px;
  font-weight: 300;
  line-height: 42px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
.uniTitRegular {
  font-family: 'UniSansRegular';
  font-size: 42px;
  font-weight: 400;
  line-height: 42px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
.uniTitRegular strong {
  color: #06377b;
  font-family: 'UniSansBold';
}
.uniTitBold {
  font-family: 'UniSansBold';
  font-size: 42px;
  font-weight: 700;
  line-height: 42px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
@media (max-width: 1024px) {
  .titulo {
    font-size: 36px;
    line-height: 40px;
  }
  .subtitulo {
    font-size: 22px;
    line-height: 28px;
  }
  .uniTitBook {
    font-family: 'UniSansBook';
    font-size: 36px;
    font-weight: 300;
    line-height: 38px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  .uniTitRegular {
    font-family: 'UniSansRegular';
    font-size: 36px;
    font-weight: 400;
    line-height: 38px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  .uniTitBold {
    font-family: 'UniSansBold';
    font-size: 36px;
    font-weight: 700;
    line-height: 38px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
}
@media (max-width: 992px) {
  .titulo {
    font-size: 28px;
    line-height: 32px;
  }
  .subtitulo {
    font-size: 20px;
    line-height: 24px;
  }
  .uniTitBook {
    font-family: 'UniSansBook';
    font-size: 28px;
    font-weight: 300;
    line-height: 30px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  .uniTitRegular {
    font-family: 'UniSansRegular';
    font-size: 28px;
    font-weight: 400;
    line-height: 30px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  .uniTitBold {
    font-family: 'UniSansBold';
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
}
@media (max-width: 480px) {
  .titulo {
    font-size: 26px;
    line-height: 30px;
    padding-bottom: 20px;
  }
  .subtitulo {
    font-size: 15px;
    line-height: 18px;
  }
  .uniTitBook {
    font-family: 'UniSansBook';
    font-size: 26px;
    font-weight: 300;
    line-height: 28px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  .uniTitRegular {
    font-family: 'UniSansRegular';
    font-size: 26px;
    font-weight: 400;
    line-height: 28px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  .uniTitBold {
    font-family: 'UniSansBold';
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
}
/* EFFECTS */
.diagonal {
  position: absolute;
  height: 2281px;
  background: rgba(0, 0, 0, 0.03);
  width: 1200px;
  z-index: 0;
  bottom: 0;
}
.dgn-left {
  left: 0;
  -moz-transform: skewX(20.2deg) translateX(-56%);
  -webkit-transform: skewX(30deg) translateX(-56%);
  -o-transform: skewX(30deg) translateX(-56%);
  -ms-transform: skewX(30deg) translateX(-56%);
  transform: skewX(30deg) translateX(-56%);
}
.dgn-right {
  right: -78px;
  -moz-transform: skewX(-20.2deg) translateX(56%);
  -webkit-transform: skewX(-20.2deg) translateX(56%);
  -o-transform: skewX(-20.2deg) translateX(56%);
  -ms-transform: skewX(-20.2deg) translateX(56%);
  transform: skewX(-20.2deg) translateX(56%);
}
@media (max-width: 1440px) {
  .dgn-right {
    right: -96px;
    height: 1370px;
  }
}
@media (max-width: 1024px) {
  .dgn-right {
    right: -173px;
    height: 1238px;
  }
}
@media (max-width: 992px) {
  .dgn-right {
    right: -206px;
    height: 1164px;
  }
}
@media (max-width: 740px) {
  .dgn-left {
    display: none !important;
  }
  .dgn-right {
    display: none;
  }
}
/* --------------------------------------------------------------
THEME DEFAULT STYLES
----------------------------------------------------------------- */
body {
  background: #f8f8f8;
  font-size: 0.75em;
}
main {
  overflow: hidden;
  width: 100%;
  position: relative;
}
/* --------------------------------------------------------------
HEADER
----------------------------------------------------------------- */
body > header .diagonalMenu {
  position: fixed;
  top: 0;
  left: -333px;
  width: 400px;
  height: 1400px;
  background: #171717;
  overflow: hidden;
  -moz-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -webkit-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -o-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -ms-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  z-index: 2;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}
body > header .diagonalMenu:before {
  content: " ";
  display: block;
  position: absolute;
  width: 1px;
  height: 60%;
  right: 0;
  bottom: 0;
  background: #a6ce39;
}
body > header .diagonalMenu:after {
  content: " ";
  display: block;
  position: absolute;
  width: 1px;
  height: 60%;
  right: 8px;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
}
body > header h1 {
  position: fixed;
  width: 270px;
  padding: 60px 0 0 30px;
  z-index: 6;
}
body > header .menu-principal-container {
  position: fixed;
  width: 270px;
  z-index: 4;
  padding: 100px 0 0 30px;
}
body > header #menu-principal {
  padding-top: 60px;
}
body > header #menu-principal > li {
  margin-bottom: 1px;
  position: relative;
  z-index: 0;
  width: 200px;
}
body > header #menu-principal > li > a {
  display: block;
  height: 40px;
  border-left: 3px solid #2f2f2f;
  padding-left: 13px;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 40px;
  color: #c8c8c8;
  font-style: normal;
  text-transform: none;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
body > header #menu-principal > li > a:hover {
  border-left: 3px solid #a6ce39;
  color: #fff;
}
body > header #menu-principal > li.menu-item-type-custom {
  z-index: 1;
  width: 290px;
}
body > header #menu-principal > li.menu-item-type-custom:hover > a {
  background: #a6ce39;
  color: #000;
  border-left: 3px solid #a6ce39;
}
body > header #menu-principal ul.sub-menu {
  position: absolute;
  background: #a6ce39;
  width: 0;
  left: 157px;
  top: 0;
  z-index: 0;
  box-shadow: inset 3px 0 6px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  overflow: hidden;
  -moz-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -webkit-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -o-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -ms-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
}
body > header #menu-principal ul.sub-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 275px;
}
body > header #menu-principal ul.sub-menu li a {
  height: 30px;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-left: 20px;
  display: block;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
body > header #menu-principal ul.sub-menu li:hover a {
  background: rgba(255, 255, 255, 0.3);
}
body > header #menu-principal > li.menu-item-type-custom:hover ul.sub-menu {
  width: 275px;
}
body > header #menu-principal > li.current-menu-item > a {
  border-left: 3px solid #a6ce39;
  color: #a6ce39;
  font-size: 20px;
}
body > header #menu-principal > li.current-menu-ancestor > a {
  border-left: 3px solid #a6ce39;
  color: #a6ce39;
  font-size: 20px;
}
body > header #menu-principal > li.current-menu-ancestor li.current-menu-item a {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}
body > header .actions {
  position: fixed;
  top: 15px;
  right: 25px;
  z-index: 6;
  padding: 0 0 0 0;
  display: none;
  width: 70px;
}
body > header .actions a {
  display: block;
  height: 70px;
  position: absolute;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 40px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  opacity: 0;
  z-index: 0;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
body > header .actions a i {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 22px;
  background: #333;
  vertical-align: middle;
  margin-left: 10px;
  border: 1px solid #a6ce39;
}
body > header .actions a.activated {
  opacity: 1;
  z-index: 1;
}
@media (max-width: 1440px) {
  body > header .diagonalMenu {
    left: 0;
    top: -25px;
    width: 100%;
    height: 100px;
    -moz-transform: rotate(0deg) skewX(0deg) skewY(-2deg);
    -webkit-transform: rotate(0deg) skewX(0deg) skewY(-2deg);
    -o-transform: rotate(0deg) skewX(0deg) skewY(-2deg);
    -ms-transform: rotate(0deg) skewX(0deg) skewY(-2deg);
    transform: rotate(0deg) skewX(0deg) skewY(-2deg);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
    z-index: 5;
  }
  body > header .diagonalMenu:before {
    height: 600px;
    transform: rotate(90.2deg);
    left: 293px;
    bottom: inherit;
    background: #a6ce39;
    top: -202px;
  }
  body > header .diagonalMenu:after {
    height: 1000px;
    transform: rotate(90.2deg);
    right: 497px;
    bottom: inherit;
    background: rgba(255, 255, 255, 0.2);
    top: -412px;
  }
  body > header h1 {
    padding: 20px 0 0 30px;
  }
  body > header h1 img {
    max-width: 100%;
  }
  body > header .menu-principal-container {
    z-index: 4;
    left: inherit;
    right: -280px;
    top: 0;
    height: 100%;
    -moz-transform: rotate(0deg) skewX(0deg) skewY(0deg);
    -webkit-transform: rotate(0deg) skewX(0deg) skewY(0deg);
    -o-transform: rotate(0deg) skewX(0deg) skewY(0deg);
    -ms-transform: rotate(0deg) skewX(0deg) skewY(0deg);
    transform: rotate(0deg) skewX(0deg) skewY(0deg);
    padding: 0;
    overflow: auto;
    -webkit-transition: all 400ms ease;
    /* Safari */
    transition: all 400ms ease;
    background: #a6ce39;
    width: 280px;
  }
  body > header .menu-principal-container.opened {
    right: 0;
  }
  body > header #menu-principal {
    padding: 110px 0 0 0;
    width: 100%;
  }
  body > header #menu-principal li {
    margin-bottom: 0;
    width: inherit;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  body > header #menu-principal li a {
    border-left: 0;
    padding: 0 20px 0 0;
    height: 36px;
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 36px;
    color: #000;
    font-style: normal;
    text-transform: none;
    text-align: right;
  }
  body > header #menu-principal li > a:hover {
    color: #fff;
    background: #333;
    border: 0;
  }
  body > header #menu-principal li.has-submenu {
    width: inherit;
    padding: 0;
  }
  body > header #menu-principal li.has-submenu > a {
    border: 0 !important;
    position: relative;
  }
  body > header #menu-principal li.has-submenu > a:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    content: "\f078";
    color: #333;
    font-size: 13px;
    position: absolute;
    left: 15px;
  }
  body > header #menu-principal li.has-submenu > a.opened:before {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  body > header #menu-principal li.has-submenu:hover > a {
    color: #fff;
    background: #333;
    border: 0;
  }
  body > header #menu-principal li.has-submenu:hover > a:before {
    color: #fff;
  }
  body > header #menu-principal ul.sub-menu {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    width: auto;
    left: inherit;
    top: inherit;
    box-shadow: none;
    transform: none;
    height: 0;
  }
  body > header #menu-principal ul.sub-menu li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: auto;
  }
  body > header #menu-principal ul.sub-menu li a {
    height: 36px;
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 36px;
    color: #000;
    font-style: normal;
    text-transform: none;
    padding-left: 20px;
    display: block;
    -webkit-transition: all 400ms ease;
    /* Safari */
    transition: all 400ms ease;
    border: 0 !important;
  }
  body > header #menu-principal ul.sub-menu li:hover a {
    background: rgba(255, 255, 255, 0.3);
    border: 0 !important;
  }
  body > header #menu-principal li.menu-item-type-custom:hover ul.sub-menu {
    width: inherit;
  }
  body > header #menu-principal > li.current-menu-item > a {
    color: #fff;
    background: #333;
    font-size: 16px;
    border: 0;
  }
  body > header #menu-principal > li.current-menu-ancestor > a {
    color: #fff !important;
    background: #333 !important;
    font-size: 16px;
    border: 0 !important;
  }
  body > header #menu-principal > li.current-menu-ancestor > a:before {
    color: #fff;
  }
  body > header #menu-principal > li.current-menu-ancestor ul.sub-menu li.current-menu-item a {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: 0 !important;
  }
  body > header .actions {
    display: block;
  }
}
@media (max-width: 1280px) {
  body > header h1 {
    width: 150px;
    padding: 12px 0 0 20px;
  }
}
body.post_cursos-template-default > header #menu-principal > li#menu-item-17 > a {
  border-left: 3px solid #a6ce39;
  color: #a6ce39;
  font-size: 20px;
}
/* --------------------------------------------------------------
BANNERS
----------------------------------------------------------------- */
/* BANNER HOME
-----------------------------*/
#bannerHome {
  height: 100%;
  width: 100%;
}
#bannerHome .imagem {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
#bannerHome .swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}
#bannerHome .swiper-slide {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #121212;
  background-attachment: inherit;
}
#bannerHome .dgn {
  position: absolute;
  height: 101%;
  background: rgba(0, 0, 0, 0.5);
  -moz-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -webkit-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -o-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -ms-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  top: 0;
  left: 0;
}
#bannerHome .dgn:after {
  content: " ";
  display: block;
  position: absolute;
  width: 1px;
  background: #a6ce39;
  right: 0;
}
#bannerHome .diagonal-1 {
  width: 63%;
  z-index: 2;
}
#bannerHome .diagonal-1:after {
  height: 70%;
  bottom: 0;
}
#bannerHome .diagonal-2 {
  width: 90%;
  z-index: 1;
}
#bannerHome .diagonal-2:after {
  height: 60%;
  top: 0;
}
#bannerHome .titleBox {
  position: absolute;
  z-index: 1;
  bottom: 100px;
  left: 50%;
  width: 600px;
  -moz-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -webkit-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -o-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -ms-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  margin-left: -520px;
}
#bannerHome .titleBox h2 {
  font-family: 'UniSansBook';
  font-size: 3.4vw;
  font-weight: 300;
  line-height: 3.7vw;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
}
#bannerHome .titleBox h3 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 20px;
  color: #c5c5c5;
  font-style: normal;
  text-transform: uppercase;
  padding-top: 20px;
}
#bannerHome.internas {
  height: 600px;
  position: relative;
}
#bannerHome.internas .titleBox {
  bottom: 40px;
  z-index: 3;
  left: inherit;
  padding-left: 15px;
  top: inherit;
}
#bannerHome.internas .titleBox h2 {
  font-family: 'UniSansBook';
  font-size: 60px;
  font-weight: 300;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
}
#bannerHome.internas .titleBox h3 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  color: #c5c5c5;
  font-style: normal;
  text-transform: uppercase;
  padding-top: 10px;
}
#bannerHome.internas .titleBox .mobilePos {
  transform: none;
}
#bannerHome.internas .container,
#bannerHome.internas .row,
#bannerHome.internas .bootstrap {
  position: relative;
  height: 100%;
}
@media (max-width: 1799px) {
  #bannerHome .titleBox {
    margin-left: -433px;
  }
}
@media (max-width: 1680px) {
  #bannerHome .titleBox {
    margin-left: -436px;
    width: 500px;
  }
}
@media (max-width: 1440px) {
  #bannerHome .titleBox {
    margin-left: -564px;
    transform: none;
    padding-left: 0;
    width: 400px;
  }
  #bannerHome.internas .titleBox {
    padding-left: 0;
  }
}
@media (max-width: 1366px) {
  #bannerHome {
    height: 700px;
  }
  #bannerHome .titleBox {
    bottom: 0;
    top: 650px;
  }
  #bannerHome .titleBox .mobilePos {
    transform: translateY(-100%);
  }
}
@media (max-width: 1170px) {
  #bannerHome .titleBox {
    margin-left: -470px;
  }
}
@media (max-width: 1024px) {
  #bannerHome {
    height: 600px;
  }
  #bannerHome .titleBox {
    left: 60px;
    top: 550px;
    margin: 0;
  }
  #bannerHome .titleBox h2 {
    font-size: 40px;
    line-height: 42px;
  }
  #bannerHome .titleBox .mobilePos {
    transform: translateY(-100%);
  }
  #bannerHome.internas {
    height: 500px;
  }
}
@media (max-width: 992px) {
  #bannerHome {
    height: 480px;
  }
  #bannerHome .dgn {
    left: -180px;
  }
  #bannerHome .diagonal-1 {
    width: 85%;
  }
  #bannerHome .diagonal-2 {
    width: 120%;
  }
  #bannerHome .titleBox {
    left: 35px;
    top: 440px;
    width: 400px;
  }
}
@media (max-width: 740px) {
  #bannerHome {
    height: 100%;
  }
  #bannerHome .titleBox {
    left: 50px;
    top: 90%;
  }
  #bannerHome.internas {
    height: 400px;
  }
}
@media (max-width: 680px) {
  #bannerHome .titleBox {
    width: 230px;
  }
  #bannerHome .titleBox h2 {
    font-size: 26px;
    line-height: 30px;
  }
  #bannerHome .titleBox h3 {
    font-size: 14px;
    line-height: 16px;
    padding-top: 10px;
  }
  #bannerHome.internas {
    height: 360px;
  }
  #bannerHome.internas .titleBox {
    width: 300px;
  }
}
@media (max-width: 480px) {
  #bannerHome {
    height: 360px;
  }
  #bannerHome .titleBox {
    left: 20px;
    top: 340px;
  }
  #bannerHome .diagonal-1 {
    width: 123%;
  }
  #bannerHome .diagonal-2 {
    width: 149%;
  }
  #bannerHome.internas {
    height: 400px;
  }
}
@media (max-width: 380px) {
  #bannerHome {
    height: 360px;
  }
  #bannerHome .titleBox {
    top: 335px;
    width: 230px;
    left: 20px;
  }
  #bannerHome .titleBox h2 {
    font-size: 26px;
    line-height: 30px;
  }
  #bannerHome .titleBox h3 {
    font-size: 14px;
    line-height: 16px;
    padding-top: 10px;
  }
  #bannerHome .diagonal-1 {
    width: 133%;
  }
  #bannerHome .diagonal-2 {
    width: 159%;
  }
  #bannerHome.internas {
    height: 360px;
  }
}
/* --------------------------------------------------------------
HOME
----------------------------------------------------------------- */
main.home .diagonal {
  bottom: 0;
  height: 2341px;
}
main.home .dgn-right {
  right: 21px;
}
@media (max-width: 1440px) {
  main.home .dgn-right {
    right: 2.3%;
  }
}
@media (max-width: 1024px) {
  main.home .dgn-right {
    right: -9%;
  }
}
@media (max-width: 992px) {
  main.home .dgn-right {
    right: -19%;
  }
}
@media (max-width: 740px) {
  main.home .dgn-right {
    display: none;
  }
}
#intro {
  overflow: hidden;
  padding-left: 200px;
}
#intro .container {
  background: url(../images/bg-faixas.png) no-repeat right top;
  background-size: auto 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
#intro h2 {
  padding-bottom: 10px;
  font-size: 38px;
  line-height: 38px;
}
#intro p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 28px;
  font-weight: 300;
  line-height: 28px;
  color: #06377b;
  font-style: italic;
  text-transform: none;
  padding: 20px 0 0 0;
}
@media (max-width: 1440px) {
  #intro {
    text-align: left;
    padding: 0;
  }
}
@media (max-width: 1024px) {
  #intro h2 {
    font-size: 28px;
    line-height: 28px;
  }
  #intro p {
    font-size: 17px;
    line-height: 18px;
  }
}
@media (max-width: 992px) {
  #intro .container {
    background: url(../images/bg-faixas.png) no-repeat right -100px top 0;
    background-size: auto 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #intro p {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 740px) {
  #intro p {
    font-size: 18px;
    line-height: 22px;
    padding: 0 0 20px 0;
    width: 100%;
    float: none;
  }
}
@media (max-width: 540px) {
  #intro .container {
    background: none;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
#servicosHome {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
}
#servicosHome #muteYouTubeVideoPlayer {
  width: 100%;
  position: relative;
  z-index: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
  background-image: url(../videos/img-thumb-video.jpg);
}
#servicosHome .grid {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background: url(../images/grid.png);
}
#servicosHome .servicos {
  background: rgba(18, 18, 18, 0.8);
  padding: 0;
  position: absolute;
  height: 100%;
  right: 0;
  width: 600px;
  transform: translateX(33%) rotate(0deg) skewX(-20.2deg);
}
#servicosHome .servicos .header {
  padding: 50px 20px 45px 20px;
  width: 300px;
}
#servicosHome .servicos h2 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 28px;
  font-weight: 300;
  line-height: 30px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 10px;
}
#servicosHome .servicos p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
#servicosHome .servicos li {
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
#servicosHome .servicos li:first-child {
  padding-top: 1px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
#servicosHome .servicos li a {
  display: block;
  height: 40px;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding: 0 20px;
  border-left: 3px solid #a6ce39;
  -webkit-transition: all 200ms ease;
  /* Safari */
  transition: all 200ms ease;
}
#servicosHome .servicos li a:hover {
  background: #a6ce39;
  color: #000;
}
@media (max-width: 1440px) {
  #servicosHome {
    height: 650px;
  }
}
@media (max-width: 1024px) {
  #servicosHome {
    height: 540px;
  }
  #servicosHome .servicos {
    right: -90px;
  }
  #servicosHome .servicos .header {
    padding: 20px 0 20px 20px;
    width: 220px;
  }
  #servicosHome .servicos h2 {
    font-size: 24px;
    line-height: 26px;
  }
  #servicosHome .servicos li a {
    height: 34px;
    line-height: 34px;
  }
}
@media (max-width: 992px) {
  #servicosHome {
    height: 490px;
  }
  #servicosHome .servicos {
    right: -110px;
  }
  #servicosHome .servicos li a {
    height: 30px;
    line-height: 30px;
  }
}
@media (max-width: 380px) {
  #servicosHome .servicos {
    right: -140px;
  }
  #servicosHome .servicos .header {
    width: 190px;
  }
  #servicosHome .servicos p {
    font-size: 13px;
    line-height: 16px;
  }
}
#personal {
  padding: 80px 0 80px 200px;
  background: #fff;
}
#personal .container {
  position: relative;
  z-index: 1;
}
#personal h2 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 15px;
}
#personal p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: #06377b;
  font-style: normal;
  text-transform: none;
}
#personal p strong {
  display: block;
  padding-top: 15px;
  font-weight: 500;
  color: #000;
}
#personal a {
  display: block;
  width: 100%;
  height: 90px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 90px;
  color: #333333;
  font-style: normal;
  text-transform: none;
}
#personal a i {
  display: inline-block;
  width: 90px;
  height: 90px;
  color: #fff;
  text-align: center;
  line-height: 90px;
  font-size: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 25px;
}
#personal a.personal {
  margin-bottom: 5px;
  background: #a6ce39;
}
#personal a.run {
  background: #06377b;
  color: #fff;
}
#personal .faixas {
  position: absolute;
  width: calc(((100% - 1338px) / 2) + 15px);
  right: 0;
  z-index: 1;
}
#personal .faixas div.verde {
  height: 90px;
  background: #a6ce39;
  margin-bottom: 5px;
}
#personal .faixas div.azul {
  height: 90px;
  background: #06377b;
}
@media (max-width: 1440px) {
  #personal {
    padding-left: 0;
  }
  #personal .faixas {
    width: 200px;
  }
}
@media (max-width: 1440px) {
  #personal .faixas {
    width: 200px;
  }
}
@media (max-width: 992px) {
  #personal {
    padding: 60px 0;
    position: relative;
  }
  #personal h2 {
    font-size: 26px;
    line-height: 30px;
  }
  #personal p {
    font-size: 16px;
    line-height: 22px;
  }
  #personal a {
    font-size: 20px;
    height: 70px;
    line-height: 70px;
    border: 0;
  }
  #personal a i {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  #personal .text {
    padding-bottom: 40px;
  }
  #personal .faixas {
    width: 100px;
    bottom: 60px;
  }
  #personal .faixas div {
    height: 70px !important;
  }
}
#cursosHome {
  padding-left: 200px;
}
@media (max-width: 1440px) {
  #cursosHome {
    padding: 0;
  }
}
.cursosList {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  overflow: hidden;
}
.cursosList:not(.home) h2 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
  color: #06377b;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
  text-align: left;
}
.cursosList ul {
  width: calc(100% + 10px);
  position: relative;
  left: -20px;
}
.cursosList ul li {
  display: inline-block;
  padding: 0 10px 30px 10px;
  width: calc(33.33% - 8px);
  vertical-align: top;
  text-align: left;
}
.cursosList ul li a.figure {
  display: block;
  width: 100%;
  height: 200px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
.cursosList ul li span {
  display: block;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #757575;
  font-style: normal;
  text-transform: none;
  padding-top: 15px;
}
.cursosList ul li h1 {
  padding-top: 10px;
}
.cursosList ul li h1 a {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  color: #333;
  font-style: normal;
  text-transform: none;
  height: 60px;
}
@media (max-width: 1440px) {
  .cursosList ul {
    left: 0;
  }
  .cursosList ul li h1 a {
    font-size: 16px;
    line-height: 20px;
    padding-right: 5px;
  }
}
@media (max-width: 1280px) {
  .cursosList ul {
    left: -20px;
    width: calc(100% + 20px);
  }
  .cursosList.home ul {
    left: -20px;
    width: calc(100% + 50px);
  }
}
@media (max-width: 1024px) {
  .cursosList {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .cursosList ul {
    left: 0;
    width: calc(100% + 10px);
  }
  .cursosList.home ul {
    left: 0;
    width: calc(100% + 10px);
  }
  .cursosList ul li {
    width: 33%;
    height: 290px;
    display: block;
    float: left;
  }
  .cursosList ul li a.figure {
    height: 170px;
  }
}
@media (max-width: 992px) {
  .cursosList ul li {
    height: 248px;
  }
  .cursosList ul li a.figure {
    height: 120px;
  }
  .cursosList ul li h1 a {
    font-size: 15px;
    line-height: 18px;
    display: block;
  }
}
@media (max-width: 540px) {
  .cursosList ul li {
    width: 50%;
    height: 250px;
  }
  .cursosList ul li a.figure {
    height: 140px;
  }
}
@media (max-width: 480px) {
  .cursosList ul {
    width: 100%;
  }
  .cursosList.home ul {
    width: 100%;
  }
  .cursosList ul li {
    width: 100%;
    height: auto;
    padding-bottom: 20px;
  }
  .cursosList ul li a.figure {
    height: 210px;
  }
}
/* --------------------------------------------------------------
CENTRO DE AVALIAÇÃO
----------------------------------------------------------------- */
main.centroAvaliacao .diagonal {
  bottom: 0;
}
main.centroAvaliacao .dgn-right {
  right: -273px;
}
main.centroAvaliacao .dgn-left {
  -moz-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -webkit-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -o-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  -ms-transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  transform: rotate(0deg) skewX(-20.2deg) skewY(0deg);
  left: -1078px;
  top: 0;
  display: none;
}
@media (max-width: 1440px) {
  main.centroAvaliacao .dgn-left {
    display: block;
  }
}
@media (max-width: 1366px) {
  main.centroAvaliacao .dgn-left {
    left: -1126px;
  }
}
@media (max-width: 1024px) {
  main.centroAvaliacao .dgn-left {
    left: -1188px;
  }
}
#solucoes {
  background: #9fc636;
  padding: 50px 0;
  padding-left: 200px;
  overflow: hidden;
  z-index: 1;
  position: relative;
}
#solucoes h2 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 38px;
  font-weight: 300;
  line-height: 40px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
#solucoes ul li {
  float: left;
  height: 45px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 20px;
  margin-left: 20px;
}
#solucoes ul li img {
  float: left;
  margin-right: 15px;
  height: 100%;
  width: auto;
}
#solucoes ul li div.content {
  height: 45px;
  position: relative;
  float: left;
  max-width: 180px;
}
#solucoes ul li div.middle {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
#solucoes ul li:first-child {
  border: 0;
  padding-left: 0;
  margin-left: 0;
}
@media (max-width: 1800px) {
  #solucoes ul li {
    height: 40px;
    padding-left: 14px;
    margin-left: 20px;
  }
  #solucoes ul li:first-child {
    margin-left: 0;
  }
  #solucoes ul li div.content {
    height: 40px;
  }
  #solucoes ul li div.middle {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 1440px) {
  #solucoes {
    padding: 30px 0;
  }
}
@media (max-width: 1024px) {
  #solucoes {
    display: none;
  }
}
#avaliacoes {
  padding: 80px 0;
  padding-left: 200px;
  position: relative;
  z-index: 1;
}
#avaliacoes article {
  border-left: 1px solid #a6ce39;
}
#avaliacoes article .loadContent {
  padding: 3px 0 3px 15px;
}
#avaliacoes article iframe,
#avaliacoes article .propBox {
  width: 100%;
  opacity: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #333;
  background-attachment: inherit;
  margin: 30px 0 35px 0;
}
#avaliacoes article h1 {
  font-family: 'UniSansRegular';
  font-size: 44px;
  font-weight: 400;
  line-height: 44px;
  color: #000;
  font-style: normal;
  text-transform: none;
}
#avaliacoes article p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  color: #06377b;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
}
#avaliacoes article ul:not(.imageList) {
  padding: 20px;
  margin: 10px 0 30px 0;
  background: #06377b;
}
#avaliacoes article ul:not(.imageList) li {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding: 5px 0 5px 12px;
  position: relative;
}
#avaliacoes article ul:not(.imageList) li:before {
  content: "•";
  position: absolute;
  left: 0;
  top: 4px;
  color: #fff;
  font-size: 26px;
}
#avaliacoes article .footer a {
  display: inline-block;
  height: 60px;
  background: #9fc636;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  cursor: pointer;
}
#avaliacoes article .footer a i {
  display: inline-block;
  vertical-align: middle;
  height: 60px;
  line-height: 60px;
  font-size: 28px;
  text-align: center;
}
#avaliacoes article .footer a i.fa-calendar {
  width: 60px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 20px;
}
#avaliacoes article .footer a i.fa-angle-right {
  width: 60px;
}
#avaliacoes article .footer a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#avaliacoes article .gallery {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  padding-top: 40px;
}
#avaliacoes article .gallery li {
  display: inline-block;
  width: calc(25% - 4px);
  height: 160px;
  margin-right: 4px;
}
#avaliacoes article .gallery li a {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
#avaliacoes article .gallery li a:hover {
  opacity: 0.7;
}
#avaliacoes aside h3 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding: 8px 0 36px 0;
}
#avaliacoes aside ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#avaliacoes aside ul li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#avaliacoes aside ul li a {
  display: block;
  height: 50px;
  padding: 0 0 0 15px;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  color: #464646;
  font-style: normal;
  text-transform: none;
  border-left: 3px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
#avaliacoes aside ul li a:hover {
  background: rgba(0, 0, 0, 0.1);
}
#avaliacoes aside ul li.current-menu-item a {
  border-left: 3px solid #9fc636;
  background: #9fc636;
  color: #fff;
  font-weight: 500;
}
@media (max-width: 1440px) {
  #avaliacoes {
    padding-left: 0;
  }
}
@media (max-width: 1024px) {
  #avaliacoes article h1 {
    font-size: 34px;
  }
  #avaliacoes article p {
    font-size: 24px;
    line-height: 30px;
  }
  #avaliacoes article ul li {
    font-size: 16px;
    line-height: 26px;
  }
  #avaliacoes article .gallery li {
    height: 120px;
  }
  #avaliacoes aside h3 {
    font-size: 24px;
    line-height: 24px;
    padding: 13px 0 36px 0;
  }
  #avaliacoes aside ul li a {
    height: auto;
    padding: 10px 0 10px 10px;
    font-size: 14px;
    line-height: 16px;
  }
}
@media (max-width: 992px) {
  #avaliacoes article {
    border: 0;
    border-bottom: 1px solid #a6ce39;
    padding-bottom: 30px;
  }
  #avaliacoes article .loadContent {
    padding: 0;
  }
  #avaliacoes article .gallery li {
    height: 140px;
  }
  #avaliacoes aside {
    padding-top: 30px;
  }
}
@media (max-width: 480px) {
  #avaliacoes article p {
    font-size: 20px;
    line-height: 24px;
  }
  #avaliacoes article .footer a {
    height: 50px;
    font-size: 16px;
    line-height: 50px;
  }
  #avaliacoes article .footer a i {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
  #avaliacoes article .footer a i.fa-calendar {
    width: 50px;
    margin-right: 10px;
  }
  #avaliacoes article .footer a i.fa-angle-right {
    width: 40px;
  }
  #avaliacoes article .gallery li {
    height: 140px;
    width: calc(50% - 4px);
  }
}
/* --------------------------------------------------------------
SERVIÇOS
----------------------------------------------------------------- */
#servicos {
  padding: 20px 0 80px 200px;
}
#servicos li {
  position: relative;
  overflow: hidden;
  padding: 50px 0 30px 0;
  border-bottom: 3px solid #a6ce39;
  clear: both;
}
#servicos h3 {
  font-family: 'UniSansRegular';
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
  font-weight: bold;
}
#servicos .text {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 70px;
  position: relative;
}
#servicos .text p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 24px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding-right: 120px;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#servicos .text p strong {
  color: #06377b;
  font-weight: 700;
}
#servicos .button {
  padding-left: 20px;
}
#servicos .button button {
  width: 100%;
  height: 70px;
  display: block;
  background: #a6ce39;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 70px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  padding: 0 0 0 18px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  cursor: pointer;
}
#servicos .button button i {
  width: 70px;
  height: 70px;
  vertical-align: middle;
  text-align: center;
  line-height: 70px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  float: right;
  font-size: 26px;
}
#servicos .button button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
@media (max-width: 1440px) {
  #servicos {
    padding-left: 0;
  }
}
@media (max-width: 1366px) {
  #servicos .text {
    height: auto;
    min-height: 70px;
  }
  #servicos .text p {
    top: inherit;
    transform: none;
    padding-right: 40px;
  }
  #servicos .button a {
    font-size: 16px;
    padding: 0 0 0 15px;
  }
  #servicos .button a i {
    width: 50px;
  }
}
@media (max-width: 1024px) {
  #servicos .text p {
    font-size: 15px;
    padding-right: 20px;
  }
  #servicos .button a {
    font-size: 18px;
    padding: 0 0 0 22px;
  }
  #servicos .button a i {
    width: 70px;
  }
}
@media (max-width: 992px) {
  #servicos {
    padding: 0 0 50px 0;
  }
  #servicos h3 {
    padding: 0 0 20px 0;
    font-size: 28px;
  }
  #servicos .text {
    border: none;
    padding: 0;
  }
  #servicos .text p {
    padding-right: 0;
  }
  #servicos .button {
    padding: 20px 0 0 0;
  }
  #servicos .button a {
    font-size: 18px;
    padding: 0 0 0 20px;
    width: auto;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    position: relative;
    left: -8px;
  }
  #servicos .button a i {
    width: 60px;
    float: none;
    margin-left: 20px;
    height: 60px;
    line-height: 60px;
  }
}
/* --------------------------------------------------------------
TEEN TEST IN GROUP
----------------------------------------------------------------- */
main.mainTennTest .diagonal {
  bottom: inherit;
  top: 0;
  z-index: 0;
}
main.mainTennTest .dgn-right {
  right: 10.1%;
}
@media (max-width: 1440px) {
  main.mainTennTest .dgn-right {
    right: -1.8%;
  }
}
@media (max-width: 1280px) {
  main.mainTennTest .dgn-right {
    right: -4.8%;
  }
}
@media (max-width: 1024px) {
  main.mainTennTest .diagonal {
    bottom: 0;
    top: inherit;
  }
  main.mainTennTest .dgn-right {
    right: -310px;
  }
}
#teenTest {
  padding: 80px 0 80px 200px;
  position: relative;
  z-index: 1;
}
#teenTest h1 {
  padding-left: 0;
  padding-bottom: 40px;
}
#teenTest a {
  height: 60px;
  display: inline-block;
  background: #9fc636;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
#teenTest a i {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 26px;
  vertical-align: middle;
}
#teenTest a i.fa-user-circle {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 26px;
}
#teenTest a i.fa-angle-right {
  margin-left: 15px;
  position: relative;
  top: -2px;
}
#teenTest a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#teenTest button {
  height: 60px;
  display: inline-block;
  background: #06377b;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  padding-left: 28px;
  cursor: pointer;
}
#teenTest button i {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 26px;
  vertical-align: middle;
}
#teenTest button i.fa-user-circle {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 26px;
}
#teenTest button i.fa-angle-right {
  margin-left: 15px;
  position: relative;
  top: -2px;
}
#teenTest button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#teenTest .text {
  padding-left: 0;
}
#teenTest .text p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 28px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding-right: 25px;
  padding-bottom: 20px;
}
#teenTest .text p strong {
  color: #06377b;
  font-weight: 700;
}
#teenTest .blueBtn {
  padding-top: 20px;
}
#teenTest .avaliacao {
  border-left: 1px solid #a6ce39;
}
#teenTest ul {
  padding-left: 15px;
}
#teenTest li {
  padding: 13px 0 13px 10px;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 22px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  position: relative;
}
#teenTest li:before {
  content: "•";
  color: #9fc636;
  position: absolute;
  left: 0;
  font-size: 22px;
}
#teenTest li:first-child {
  padding-top: 0;
}
#teenTest .greenBtn {
  padding: 40px 0 0 15px;
}
@media (max-width: 1440px) {
  #teenTest {
    padding-left: 0;
  }
}
@media (max-width: 992px) {
  #teenTest {
    padding: 60px 0;
  }
  #teenTest .text {
    padding-bottom: 40px;
  }
  #teenTest .text p {
    padding-right: 0;
  }
  #teenTest .avaliacao {
    border: 0;
    border-top: 1px solid #a6ce39;
    padding-top: 40px;
    padding-left: 0;
  }
  #teenTest ul,
  #teenTest .greenBtn {
    padding-left: 0;
  }
}
main.mainTennTest .banner {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 690px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
@media (max-width: 1440px) {
  main.mainTennTest .banner {
    height: 500px;
  }
}
/* --------------------------------------------------------------
PERSONAL
----------------------------------------------------------------- */
main.mainPersonal .diagonal {
  bottom: inherit;
  top: 0;
  z-index: 0;
}
main.mainPersonal .dgn-right {
  right: 10.1%;
}
@media (max-width: 1440px) {
  main.mainPersonal .dgn-right {
    right: -1.8%;
  }
}
@media (max-width: 1280px) {
  main.mainPersonal .dgn-right {
    right: -4.8%;
  }
}
@media (max-width: 1024px) {
  main.mainPersonal .diagonal {
    bottom: 0;
    top: inherit;
  }
  main.mainPersonal .dgn-right {
    right: -310px;
  }
}
.personalPage {
  padding: 60px 0 50px 0;
}
.personalPage article {
  overflow: hidden;
  z-index: 1;
  position: relative;
}
.personalPage article p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
}
.personalPage article a.btnGreen {
  height: 60px;
  display: inline-block;
  background: #9fc636;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  padding-left: 20px;
  margin-bottom: 10px;
}
.personalPage article a.btnGreen i {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 26px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.personalPage article a.btnGreen:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.personalPage article button {
  height: 60px;
  display: inline-block;
  background: #9fc636;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  padding-left: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.personalPage article button i {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 26px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.personalPage article button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.personalPage article iframe,
.personalPage article .propBox {
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
}
.personalPage article .padding {
  padding-right: 25px;
}
.personalPage article .swiper-container {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;
}
.personalPage article .swiper-slide {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
.personalPage article .navigation a {
  z-index: 1;
  width: 72px;
  height: 72px;
  background: #9fc636;
  border: 6px solid #f4f4f4;
  border-radius: 100px;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  text-align: center;
}
.personalPage article .navigation a i {
  color: #fff;
  font-size: 40px;
  line-height: 55px;
}
.personalPage article .navigation a.arrow-left {
  left: 0;
  transform: translate(-40%, -50%);
}
.personalPage article .navigation a.arrow-right {
  right: 0;
  transform: translate(40%, -50%);
}
.personalPage.intro {
  background: #f4f4f4;
  padding: 80px 0 40px 200px;
}
.personalPage.intro p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: #06377b;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
.personalPage.intro p strong {
  display: inline-block;
  padding: 10px 15px;
  background: #06377b;
  color: #fff;
  font-weight: 500;
}
.personalPage.personalTraining {
  background: #fff;
  padding-left: 200px;
}
.personalPage.runCoach {
  background: #f4f4f4;
  padding-left: 200px;
}
@media (max-width: 1440px) {
  .personalPage.intro {
    padding-left: 0;
  }
  .personalPage.personalTraining {
    padding-left: 0;
  }
  .personalPage.runCoach {
    padding-left: 0;
  }
  .personalPage article .navigation a.arrow-left {
    left: 0;
    transform: translate(-25%, -50%);
  }
  .personalPage article .navigation a.arrow-right {
    right: 0;
    transform: translate(25%, -50%);
  }
}
@media (max-width: 992px) {
  .personalPage article .texto {
    padding-bottom: 30px;
  }
  .personalPage article .padding {
    padding-right: 0;
  }
}
@media (max-width: 740px) {
  .personalPage {
    padding: 40px 0 20px 0;
  }
  .personalPage.intro {
    padding: 40px 0 20px 0;
  }
  .personalPage.intro p {
    font-size: 16px;
  }
}
/* --------------------------------------------------------------
CURSOS
----------------------------------------------------------------- */
main.mainCursos .diagonal {
  bottom: inherit;
  top: 0;
  z-index: 0;
}
main.mainCursos .dgn-right {
  right: 10.1%;
}
@media (max-width: 1440px) {
  main.mainCursos .dgn-right {
    right: -1.8%;
  }
}
@media (max-width: 1280px) {
  main.mainCursos .dgn-right {
    right: -4.8%;
  }
}
@media (max-width: 1024px) {
  main.mainCursos .diagonal {
    bottom: 0;
    top: inherit;
  }
  main.mainCursos .dgn-right {
    right: -310px;
  }
}
#cursosPage .container {
  position: relative;
  z-index: 1;
}
#cursosPage .intro {
  background: #9fc636;
  padding: 50px 0;
  padding-left: 200px;
}
#cursosPage .intro p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 44px;
  color: #000;
  font-style: normal;
  text-transform: none;
}
#cursosPage .informacoes {
  padding: 80px 0;
  padding-left: 200px;
  background: #fff;
}
#cursosPage .informacoes li {
  padding: 0 65px 50px 0;
}
#cursosPage .informacoes li h3 {
  display: inline-block;
  padding: 10px 15px;
  background: #06377b;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  margin-bottom: 30px;
}
#cursosPage .informacoes li p,
#cursosPage .informacoes li address {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #000;
  font-style: normal;
  text-transform: none;
}
#cursosPage .informacoes li a {
  color: #06377b;
}
#cursosPage .informacoes li:last-child {
  padding-bottom: 0;
}
#cursosPage .informacoes ul:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
#cursosPage .informacoes ul:last-child {
  border: 0;
}
#cursosPage .informacoes ul:last-child li {
  padding: 0 0 50px 35px;
}
#cursosPage .informacoes ul:last-child li p {
  padding-bottom: 25px;
}
#cursosPage .informacoes ul:last-child li p:last-child {
  padding: 0;
}
#cursosPage .informacoes ul:last-child li p strong {
  display: block;
}
#cursosPage .cursosList {
  margin-left: 200px;
  padding-bottom: 40px;
}
#cursosPage .cursosList ul li {
  width: calc(33.33% - 8px);
  padding: 0 10px;
  padding-bottom: 30px;
}
#cursosPage .cursosList ul li a.figure {
  height: 200px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
#cursosPage .cursosList ul li h1 {
  height: 60px;
}
@media (max-width: 1440px) {
  #cursosPage .intro {
    padding-left: 0;
  }
  #cursosPage .informacoes {
    padding-left: 0;
  }
  #cursosPage .cursosList {
    margin-left: 0;
  }
}
@media (max-width: 1024px) {
  #cursosPage .intro {
    padding: 40px 0;
  }
  #cursosPage .intro p {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  #cursosPage .informacoes {
    padding: 60px 0;
  }
  #cursosPage .informacoes li {
    padding: 0 0 50px 0;
  }
  #cursosPage .informacoes li h3 {
    padding: 7px 12px;
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
    font-style: normal;
    text-transform: none;
    margin-bottom: 20px;
  }
  #cursosPage .informacoes ul:first-child {
    border: 0;
    padding-bottom: 50px;
  }
  #cursosPage .informacoes ul:last-child li {
    padding: 0 0 50px 0;
  }
  #cursosPage .informacoes ul:last-child li p {
    padding-bottom: 15px;
  }
}
@media (max-width: 940px) {
  #cursosPage .cursosList ul li {
    height: 250px;
  }
  #cursosPage .cursosList ul li a.figure {
    height: 140px;
  }
}
@media (max-width: 540px) {
  #cursosPage .cursosList ul li {
    width: 50%;
    height: 250px;
  }
}
@media (max-width: 480px) {
  #cursosPage .intro {
    padding: 30px 0;
  }
  #cursosPage .intro p {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  #cursosPage .cursosList ul {
    width: 100%;
  }
  #cursosPage .cursosList ul li {
    width: 100%;
    height: auto;
    padding-bottom: 40px;
  }
  #cursosPage .cursosList ul li a.figure {
    height: 210px;
  }
  #cursosPage .cursosList ul li h1 {
    height: auto;
  }
  #cursosPage .informacoes {
    padding: 40px 0;
  }
  #cursosPage .informacoes li h3 {
    padding: 5px 10px;
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    font-style: normal;
    text-transform: none;
    margin-bottom: 20px;
  }
  #cursosPage .informacoes li p {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  #cursosPage .informacoes ul:last-child li:last-child {
    padding: 0;
  }
}
/* --------------------------------------------------------------
SINGLE CURSOS
----------------------------------------------------------------- */
#bannerHome.internas .swiper-slide {
  padding-left: 200px;
}
@media (max-width: 1440px) {
  #bannerHome.internas .swiper-slide {
    padding-left: 0;
  }
}
#bannerHome.internas .titleBox {
  left: inherit !important;
  margin: 0 !important;
}
#bannerHome.internas .titleBox h2 {
  font-size: 50px;
  line-height: 54px;
}
@media (max-width: 1440px) {
  #bannerHome.internas .titleBox h2 {
    font-size: 45px;
    line-height: 50px;
  }
}
@media (max-width: 1366px) {
  #bannerHome.internas .titleBox h2 {
    font-size: 42px;
    line-height: 46px;
  }
}
@media (max-width: 1024px) {
  #bannerHome.internas .titleBox {
    width: 360px;
  }
}
@media (max-width: 480px) {
  #bannerHome.internas .titleBox {
    width: 360px;
  }
  #bannerHome.internas .titleBox h2 {
    font-size: 38px;
    line-height: 42px;
  }
  #bannerHome.internas .titleBox h3 {
    font-size: 20px;
    line-height: 20px;
  }
}
@media (max-width: 320px) {
  #bannerHome.internas .titleBox {
    width: 340px;
  }
  #bannerHome.internas .titleBox h2 {
    font-size: 30px;
    line-height: 32px;
  }
  #bannerHome.internas .titleBox h3 {
    font-size: 16px;
    line-height: 18px;
  }
}
main.mainCursoSingle .diagonal {
  bottom: inherit;
  top: 0;
  z-index: 0;
}
main.mainCursoSingle .dgn-right {
  right: 10.1%;
}
@media (max-width: 1440px) {
  main.mainCursoSingle .dgn-right {
    right: -1.8%;
    height: 1593px;
  }
}
@media (max-width: 1280px) {
  main.mainCursoSingle .dgn-right {
    right: -4.8%;
  }
}
@media (max-width: 1024px) {
  main.mainCursoSingle .dgn-right {
    right: -310px;
  }
}
#cursoSingle .container {
  position: relative;
  z-index: 1;
}
#cursoSingle .intro {
  background: #9fc636;
  padding: 40px 0;
  padding-left: 200px;
}
#cursoSingle .intro p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 44px;
  color: #000;
  font-style: normal;
  text-transform: none;
}
#cursoSingle .singleListaCursos {
  padding-left: 200px;
}
#cursoSingle .informacoes {
  padding: 60px 0;
  background: #f2f2f2;
  padding-left: 200px;
}
#cursoSingle .informacoes h3 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
  color: #000;
  font-style: normal;
  text-transform: none;
  margin-bottom: 20px;
}
#cursoSingle .informacoes h4 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #06377b;
  font-style: normal;
  text-transform: uppercase;
  padding: 10px 0;
}
#cursoSingle .informacoes p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
}
#cursoSingle .informacoes ul {
  -moz-column-count: 2;
  -moz-column-gap: 80px;
  -webkit-column-count: 2;
  -webkit-column-gap: 80px;
  column-count: 2;
  column-gap: 80px;
  -webkit-column-rule: 1px solid #a6ce39;
  -moz-column-rule: 1px solid #a6ce39;
  column-rule: 1px solid #a6ce39;
  padding-top: 10px;
}
#cursoSingle .informacoes ul li {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  color: #000;
  font-style: italic;
  text-transform: none;
  padding: 10px 0 10px 15px;
  position: relative;
}
#cursoSingle .informacoes ul li:before {
  content: "•";
  position: absolute;
  left: 0;
  top: 10px;
  color: #a6ce39;
  font-size: 26px;
}
#cursoSingle .informacoes button {
  height: 60px;
  display: inline-block;
  padding-left: 20px;
  background: #06377b;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  padding: 0 0 0 18px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  cursor: pointer;
}
#cursoSingle .informacoes button i {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  line-height: 60px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  float: right;
  font-size: 26px;
  margin-left: 20px;
}
#cursoSingle .informacoes button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#cursoSingle .calendarios {
  padding: 80px 0 50px 0;
  background: #fff;
  padding-left: 200px;
}
#cursoSingle .calendarios .wrapCalendar {
  float: left;
}
#cursoSingle .calendarios .calendars {
  margin-right: 40px;
  margin-bottom: 20px;
  background: #9ec33b;
  border-radius: 5px;
}
#cursoSingle .calendarios .calendars .calendar-hd {
  height: 60px;
  padding: 0;
  text-align: center;
}
#cursoSingle .calendarios .calendars .calendar-hd a {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  cursor: default;
  display: block;
  float: none;
}
#cursoSingle .calendarios .calendars .calendar-views .week li {
  background: #799335;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  height: 30px;
}
#cursoSingle .calendarios .calendars .days li {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
}
#cursoSingle .calendarios .calendars .days li.now {
  background: #799335;
}
#cursoSingle .calendarios .calendars .days li.new {
  color: #799335;
}
#cursoSingle .calendarios a.btnQueroInscrever {
  height: 60px;
  width: 280px;
  display: inline-block;
  background: #06377b;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  padding: 0 0 0 18px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  cursor: pointer;
  margin-bottom: 30px;
}
#cursoSingle .calendarios a.btnQueroInscrever:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#cursoSingle .calendarios a.btnQueroInscrever i {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  line-height: 60px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  float: right;
  font-size: 26px;
  margin-left: 20px;
}
#cursoSingle .calendarios .infoCurso {
  float: left;
}
#cursoSingle .calendarios .infoCurso strong {
  display: block;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #06377b;
  font-style: normal;
  text-transform: uppercase;
}
#cursoSingle .calendarios .infoCurso p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
#cursoSingle .calendarios .infoCurso a {
  height: 60px;
  display: inline-block;
  padding-left: 20px;
  background: #06377b;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  padding: 0 0 0 18px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  cursor: pointer;
}
#cursoSingle .calendarios .infoCurso a i {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  line-height: 60px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  float: right;
  font-size: 26px;
  margin-left: 20px;
}
#cursoSingle .calendarios .infoCurso a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#cursoSingle .cancelamento {
  padding: 40px 0 50px 0;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 200px;
}
#cursoSingle .cancelamento h2 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #06377b;
  font-style: normal;
  text-transform: uppercase;
  padding-bottom: 20px;
}
#cursoSingle .cancelamento p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
}
#cursoSingle .cancelamento li {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding: 5px 0 5px 15px;
  position: relative;
}
#cursoSingle .cancelamento li:before {
  content: "•";
  position: absolute;
  left: 0;
  top: 10px;
  color: #a6ce39;
  font-size: 26px;
}
@media (max-width: 1440px) {
  #cursoSingle .intro,
  #cursoSingle .informacoes,
  #cursoSingle .calendarios,
  #cursoSingle .singleListaCursos,
  #cursoSingle .cancelamento {
    padding-left: 0;
  }
}
@media (max-width: 1366px) {
  #cursoSingle .informacoes ul li {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
}
@media (max-width: 1024px) {
  #cursoSingle .intro {
    padding: 40px 0;
  }
  #cursoSingle .intro p {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  #cursoSingle .informacoes ul li {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  #cursoSingle .calendarios {
    padding: 60px 0 30px 0;
  }
  #cursoSingle .calendarios .calendars {
    margin-right: 30px;
  }
}
@media (max-width: 740px) {
  #cursoSingle .informacoes h3 {
    font-size: 30px;
    line-height: 30px;
  }
  #cursoSingle .calendarios .calendars {
    margin-right: 25px;
  }
}
@media (max-width: 480px) {
  #cursoSingle .intro {
    padding: 30px 0;
  }
  #cursoSingle .intro p {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #000;
    font-style: normal;
    text-transform: none;
  }
  #cursoSingle .informacoes ul {
    -moz-column-count: 1;
    -moz-column-gap: 0;
    -webkit-column-count: 1;
    -webkit-column-gap: 0;
    column-count: 1;
    column-gap: 0;
    -webkit-column-rule: 0 solid #a6ce39;
    -moz-column-rule: 0 solid #a6ce39;
    column-rule: 0 solid #a6ce39;
  }
}
/* --------------------------------------------------------------
Contato
----------------------------------------------------------------- */
main.contatoPage .diagonal {
  bottom: inherit;
  top: 0;
  z-index: 0;
}
main.contatoPage .dgn-right {
  right: 10.1%;
}
@media (max-width: 1440px) {
  main.contatoPage .dgn-right {
    right: -1.8%;
  }
}
@media (max-width: 1280px) {
  main.contatoPage .dgn-right {
    right: -4.8%;
  }
}
@media (max-width: 1024px) {
  main.contatoPage .diagonal {
    bottom: 0;
    top: inherit;
  }
  main.contatoPage .dgn-right {
    right: -310px;
  }
}
#contato {
  padding: 0 0 80px 200px;
  position: relative;
  z-index: 2;
}
#contato .intro {
  padding-top: 80px;
  padding-bottom: 60px;
}
#contato .intro p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #06377b;
  font-style: normal;
  text-transform: none;
}
#contato .intro .alert p {
  padding: 20px;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #856404;
  font-style: normal;
  text-transform: none;
  border-radius: 10px;
}
#contato .intro .alert p i {
  font-size: 20px;
  width: 30px;
  position: relative;
  top: 1px;
}
#contato .intro .alert p span {
  display: block;
  width: calc(100% - 30px);
}
#contato .form {
  position: relative;
  overflow: hidden;
}
#contato .form fieldset {
  padding-top: 13px;
}
#contato .form:not(.inscricao) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 481px) {
  #contato .form.inscricao fieldset {
    width: calc(100% + 30px);
  }
}
#contato .form p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
}
#contato .form p span {
  color: #f51d1d;
}
#contato .form .input {
  width: calc(100% - 30px);
  padding-bottom: 25px;
}
#contato .form .input.half {
  width: calc(50% - 30px);
  margin-right: 30px;
  float: left;
}
#contato .form .input p {
  padding: 0;
}
#contato .form label {
  display: block;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding-bottom: 10px;
}
#contato .form label span {
  color: #f51d1d;
  font-size: 14px;
}
#contato .form input {
  height: 50px;
}
#contato .form input:not(.submit) {
  width: 100%;
  padding: 0 15px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
  color: #06377b;
  font-style: normal;
  text-transform: none;
}
#contato .form textarea {
  width: 100%;
  padding: 10px 15px;
  height: 245px;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #06377b;
  font-style: normal;
  text-transform: none;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
#contato .form select {
  height: 50px;
  width: 100%;
  padding: 0 15px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
  color: #333333;
  font-style: normal;
  text-transform: none;
}
#contato .form select option {
  padding: 15px;
  height: 30px;
}
#contato .form div.captcha {
  text-align: right;
  padding-right: 30px;
}
#contato .form div.captcha p {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: flex-start;
  align-items: center;
}
#contato .form div.captcha input.wpcf7-submit {
  width: 100px;
  display: inline-block;
  background: #9fc636;
  cursor: pointer;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
#contato .form div.captcha input.wpcf7-submit:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #a6ce39;
}
#contato .form .wpcf7-not-valid-tip {
  position: absolute;
}
#contato .form .wpcf7-response-output.alert-danger,
#contato .form .wpcf7-response-output.alert-warning {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding: 20px;
  text-align: center;
  background: #FFECEE;
  border: 2px solid #FFACB6;
}
#contato .form .wpcf7-response-output.alert-success {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding: 20px;
  text-align: center;
  background: #ECFFEF;
  border: 2px solid #C8ECC9;
}
#contato .sidebar h3 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 15px;
}
#contato .sidebar h3 i {
  vertical-align: middle;
  width: 30px;
  height: 40px;
  line-height: 35px;
  color: #06377b;
  font-size: 26px;
}
#contato .sidebar address {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
}
#contato .sidebar p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #333333;
  font-style: normal;
  text-transform: none;
}
#contato .sidebar a.tel {
  color: #333333;
}
#contato .sidebar a.button {
  height: 60px;
  padding-right: 20px;
  display: inline-block;
  background: #009846;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  text-align: left;
}
#contato .sidebar a.button i {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
#contato .sidebar a.button.green span {
  display: inline-block;
  font-size: 24px;
  text-transform: none;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 15px;
}
#contato .sidebar a.button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#contato .sidebar strong {
  display: block;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #06377b;
  font-style: normal;
  text-transform: none;
  padding-bottom: 6px;
}
#contato .sidebar form {
  width: 100%;
  padding: 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
#contato .sidebar form input:not(.submit) {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
  color: #06377b;
  font-style: italic;
  text-transform: none;
  width: calc(100% - 60px);
  height: 50px;
  padding: 0 15px;
}
#contato .sidebar form input.submit {
  width: 50px;
  height: 50px;
  background: #06377b url(../images/bg_submit_maps.png) center center;
  background-size: canvas;
  text-indent: -9999px;
  cursor: pointer;
}
#contato .sidebar .infoCurso {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 35px 0 0 0;
}
#contato .sidebar .infoCurso strong {
  display: block;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #06377b;
  font-style: normal;
  text-transform: uppercase;
}
#contato .sidebar .infoCurso p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
#contato .sidebar .maps {
  padding: 35px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#contato .sidebar .telefones {
  padding: 40px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#contato .sidebar .whatsapp {
  padding-top: 40px;
}
@media (max-width: 1440px) {
  #contato {
    padding-left: 0;
  }
}
@media (max-width: 992px) {
  #contato {
    padding: 0 0 60px 0;
  }
  #contato .intro {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  #contato .form fieldset {
    border: none;
  }
  #contato .form .input {
    width: 100%;
  }
}
@media (max-width: 480px) {
  #contato .intro {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  #contato .form {
    padding-bottom: 40px;
  }
  #contato .form .input.half {
    width: 100%;
    margin: 0;
  }
  #contato .form div.captcha {
    padding-right: 0;
  }
  #contato .form div.captcha label strong {
    font-size: 14px;
  }
  #contato .form div.captcha input:not(.submit) {
    width: 40px;
    margin: 0 5px;
    font-size: 16px;
    padding: 0;
  }
  #contato .form div.captcha input.submit {
    width: 80px;
    font-size: 16px;
  }
  #contato .form div.captcha img {
    margin: 0 20px;
  }
  #contato .sidebar .maps {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
main.sucessoPage .diagonal {
  bottom: inherit;
  top: 0;
  z-index: 0;
}
main.sucessoPage .dgn-right {
  right: 10.1%;
}
@media (max-width: 1440px) {
  main.sucessoPage .dgn-right {
    right: -1.8%;
  }
}
@media (max-width: 1280px) {
  main.sucessoPage .dgn-right {
    right: -4.8%;
  }
}
@media (max-width: 1024px) {
  main.sucessoPage .diagonal {
    bottom: 0;
    top: inherit;
  }
  main.sucessoPage .dgn-right {
    right: -310px;
  }
}
#sucesso {
  padding: 80px 0 80px 200px;
  z-index: 2;
  position: relative;
}
#sucesso li {
  position: relative;
  overflow: hidden;
  padding: 50px 0 30px 0;
  border-bottom: 3px solid #a6ce39;
  clear: both;
}
#sucesso h3 {
  font-family: 'UniSansRegular';
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  color: #009d0b;
  font-style: normal;
  text-transform: none;
  padding-bottom: 60px;
  font-weight: bold;
}
#sucesso p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  padding-right: 120px;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 20px;
}
#sucesso a {
  height: 60px;
  display: inline-block;
  background: #9fc636;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  padding: 0 30px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  cursor: pointer;
}
#sucesso a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
@media (max-width: 1440px) {
  #sucesso {
    padding-left: 0;
  }
}
@media (max-width: 740px) {
  #sucesso p {
    padding-right: 0;
  }
}
@media (max-width: 540px) {
  #sucesso h3 {
    font-size: 26px;
    line-height: 28px;
  }
  #sucesso p {
    font-size: 18px;
    line-height: 22px;
  }
}
/* --------------------------------------------------------------
FOOTER
----------------------------------------------------------------- */
body > footer .informacoes {
  background: #06377b;
  padding: 40px 0 40px 200px;
  text-align: center;
}
body > footer .informacoes address {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 10px;
}
body > footer .informacoes .telefones a {
  display: inline-block;
}
body > footer .informacoes .telefones a span {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
body > footer .informacoes .telefones strong {
  padding: 0 10px;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.2);
  font-style: normal;
  text-transform: none;
}
body > footer .informacoes .whatsapp {
  padding: 30px 0 20px 0;
  text-align: center;
}
body > footer .informacoes .whatsapp a {
  display: inline-block;
  height: 50px;
  border: 2px solid #fff;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 46px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  padding-right: 20px;
}
body > footer .informacoes .whatsapp a i {
  width: 50px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  font-size: 26px;
  vertical-align: middle;
  position: relative;
  top: -4px;
}
body > footer .informacoes .whatsapp a span {
  display: inline-block;
  font-size: 20px;
  text-transform: none;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 15px;
}
body > footer .informacoes .whatsapp a:hover {
  background: rgba(255, 255, 255, 0.2);
}
body > footer .informacoes .map {
  padding: 0 0 20px 0;
}
body > footer .informacoes .map a {
  display: inline-block;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
}
body > footer .informacoes .map a i {
  font-size: 16px;
  margin-right: 5px;
}
body > footer .informacoes .social li {
  display: inline-block;
}
body > footer .informacoes .social li a {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 4px;
}
body > footer .informacoes .social li a i {
  line-height: 30px;
  color: #fff;
  font-size: 22px;
}
body > footer .rodape {
  height: 51px;
  background: #2f2f2f;
  padding-left: 200px;
}
body > footer .rodape p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 50px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
body > footer .rodape a {
  display: block;
  float: right;
}
@media (max-width: 1440px) {
  body > footer .informacoes {
    padding-left: 0;
  }
  body > footer .rodape {
    padding-left: 0;
  }
}
@media (max-width: 1024px) {
  body > footer .rodape {
    height: auto;
    text-align: center;
    padding: 20px 0;
  }
  body > footer .rodape p {
    padding: 10px 0;
    line-height: 16px;
    float: none;
    width: 100%;
  }
  body > footer .rodape a {
    display: inline-block;
    float: none !important;
  }
}
.floatButtonsWpp {
  position: fixed;
  z-index: 3;
  display: block;
  right: 30px;
  bottom: 30px;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .floatButtonsWpp {
    bottom: 10px;
    right: 10px;
  }
  .floatButtonsWpp img {
    width: auto;
    height: 60px;
  }
}
/* ALERTAS */
#lightbox {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  opacity: 0;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
#lightbox div {
  margin: 0 auto;
  position: relative;
  width: 80%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
#lightbox div p {
  padding: 20px 40px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}
#lightbox a {
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 40px;
  width: 40px;
  line-height: 32px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 30px;
  font-size: 16px;
}
#lightbox .sucesso {
  background: #6eaa53;
}
#lightbox .sucesso a {
  background: #6eaa53;
}
#lightbox .erro {
  background: #f5506b;
}
#lightbox .erro a {
  background: #f5506b;
}
