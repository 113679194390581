
/* --------------------------------------------------------------
RESET
----------------------------------------------------------------- */

* { margin:0; padding:0; outline:none; border:0; list-style-type:none; text-decoration:none; list-style-image:none; box-sizing:border-box;}

html{font-size:100%; -webkit-text-size-adjust:100%; -ms-text-size-adjust:100%;}
header, section, footer, article, figcaption, hgroup, figure, details, aside, img, nav, time, bloquote, svg {display:block;}
audio, canvas, video {display:inline-block;*display:inline;*zoom:1;}
audio:not([controls]){display:none;}
[hidden]{display:none;}

object[type="application/gas-events-uni"] {width:0; height:0; position:absolute; overflow:hidden; display:block; text-indent:-999999px}

input[type="search"], input[type="submit"] {-webkit-appearance:textfield; -moz-box-sizing:content-box; -webkit-box-sizing:content-box; box-sizing:content-box; border-radius:0; -webkit-border-radius:0;}

/* --------------------------------------------------------------
GRID SYSTEM
----------------------------------------------------------------- */

.wrap {width:~"calc(100% - 16%)"; margin:0 8%; position:relative; clear:both;} 
.dryWrap {width:96%; margin:0 28px; position:relative; clear:both;}


/* --------------------------------------------------------------
DEFAULT STYLES
----------------------------------------------------------------- */

html,body {height:100%;}

/* FORMATING CLASSES
---------------------------- */

.clear {clear:both;}
.hide {width:0; height:0; position:absolute; overflow:hidden; display:block; text-indent:-999999px}
hr {display:none;}

.left {float:left;}
.right {float:right;}
.center {margin:0 auto;}
.middle {display:block; position:relative; top:50%; transform:translateY(-50%);}
.middleOff {top:inherit; transform:none;}

.textRight {text-align:right;}
.textLeft {text-align:left;}
.textCenter {text-align:center;}

img.alignright {float:right; margin:0 0 1em 1em}
img.alignleft {float:left; margin:0 1em 1em 0}
img.aligncenter {display: block; margin-left: auto; margin-right: auto}

a img.alignright {float:right; margin:0 0 1em 1em}
a img.alignleft {float:left; margin:0 1em 1em 0}
a img.aligncenter {display: block; margin-left: auto; margin-right: auto}

.padding60 {padding-top:60px; padding-bottom:60px}
.padding40 {padding-top:40px; padding-bottom:40px}

span.bar {display:block; margin:0 auto; margin-top:15px; width:36px; height:1px; background:rgba(255,255,255,0.2);}

.cols(@col : 2, @gap : 40px){
  -moz-column-count: @col;
  -moz-column-gap: @gap;
  -webkit-column-count: @col;
  -webkit-column-gap: @gap;
  column-count: @col;
  column-gap: @gap;
}

.colsRules(@size : 1px, @style : solid, @cor : rgba(0,0,0,0.1)){
  -webkit-column-rule: @size @style @cor;
  -moz-column-rule: @size @style @cor;
  column-rule: @size @style @cor;
}

.rotate(@grau : 20.20deg){
  -moz-transform: rotate(@grau);
  -webkit-transform: rotate(@grau);
  -o-transform: rotate(@grau);
  -ms-transform: rotate(@grau);
  transform: rotate(@grau);
}

.skewX(@grau : -20.2deg){
  -moz-transform: rotate(0deg) skewX(@grau) skewY(0deg);
  -webkit-transform: rotate(0deg) skewX(@grau) skewY(0deg);
  -o-transform: rotate(0deg) skewX(@grau) skewY(0deg);
  -ms-transform: rotate(0deg) skewX(@grau) skewY(0deg);
  transform: rotate(0deg) skewX(@grau) skewY(0deg);
}
.skewY(@grau : -20.2deg){
  -moz-transform: rotate(0deg) skewX(0deg) skewY(@grau);
  -webkit-transform: rotate(0deg) skewX(0deg) skewY(@grau);
  -o-transform: rotate(0deg) skewX(0deg) skewY(@grau);
  -ms-transform: rotate(0deg) skewX(0deg) skewY(@grau);
  transform: rotate(0deg) skewX(0deg) skewY(@grau);
}

/* SIZES */

.full_X {width:100% !important; position:relative;}
.full_Y {height:100% !important; position:relative;}
.fullSize {width:100% !important; height:100% !important; position:relative;}

/* BACKGROUNDS */

.bgCover(@cor : rgba(25,25,25,0.9), @att : inherit) {
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  background-color:@cor;
  background-attachment: @att;
}

.bgContain(@cor : rgba(25,25,25,0.9), @att : inherit) {
  -webkit-background-size:contain;
  -moz-background-size:contain;
  -o-background-size:contain;
  background-size:contain;
  background-repeat:no-repeat;
  background-position:center center;
  background-color:@cor;
  background-attachment: @att;
}

/* COLORS */

@verde : #a6ce39;
@verdeEscuro : #9fc636;
@azul : #06377b;
@textos : #333333;
@links : #464646;

/* FONTS */

@import "https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:300,400,500,700";

@font-face {
  font-family:'UniSansBook';
  src:  url('fontes/UniSansBook.eot?#iefix') format('embedded-opentype'),
        url('fontes/UniSansBook.woff') format('woff'),
        url('fontes/UniSansBook.ttf')  format('truetype'),
        url('fontes/UniSansBook.svg#UniSansBook') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'UniSansRegular';
  src:  url('fontes/UniSansRegular.eot?#iefix') format('embedded-opentype'),
        url('fontes/UniSansRegular.woff') format('woff'),
        url('fontes/UniSansRegular.ttf')  format('truetype'),
        url('fontes/UniSansRegular.svg#UniSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'UniSansBold';
  src:  url('fontes/UniSansBold.eot?#iefix') format('embedded-opentype'),
        url('fontes/UniSansBold.woff') format('woff'),
        url('fontes/UniSansBold.ttf')  format('truetype'),
        url('fontes/UniSansBold.svg#UniSansBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*
FIRA
Light 300, Normal 400, Medium 500, Bold 700
*/

.fira(@size : 13px, @weight : 400, @lineHeight : 18px,@cor : @texto3, @style : normal, @upper : none){
  font-family:'Fira Sans Condensed',sans-serif;
  font-size:@size;
  font-weight:@weight;
  line-height:@lineHeight;
  color:@cor;
  font-style:@style;
  text-transform:@upper;
}

.uni(@size : 13px, @weight : 400, @lineHeight : 18px,@cor : @texto3, @style : normal, @upper : none){
  
  & when (@weight = 300) {font-family:'UniSansBook';}
  & when (@weight = 400) {font-family:'UniSansRegular';}
  & when (@weight = 700) {font-family:'UniSansBold';}
  
  font-size:@size;
  font-weight:@weight;
  line-height:@lineHeight;
  color:@cor;
  font-style:@style;
  text-transform:@upper;

}


.fontaws(@content : "\f000", @size : 18px, @cor : #000){
  font-family:FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content:@content;
  color:@cor;
  font-size:@size;
}

.titulo {.fira(42px,300,46px,#000,normal,none); padding-bottom:30px;}
.titulo strong {color:@azul; font-weight:700;}
.subtitulo {.fira(26px,400,30px,@azul,normal,none);}

.uniTitBook {.uni(42px,300,42px,#000,normal,none); padding-bottom:30px;}
.uniTitRegular {.uni(42px,400,42px,#000,normal,none); padding-bottom:30px;}
.uniTitRegular strong {color:@azul; font-family:'UniSansBold';}
.uniTitBold {.uni(42px,700,42px,#000,normal,none); padding-bottom:30px;}

@media (max-width : 1024px)
{
  .titulo {font-size:36px; line-height:40px;}
  .subtitulo {font-size:22px; line-height:28px;}
  .uniTitBook {.uni(36px,300,38px,#000,normal,none);}
  .uniTitRegular {.uni(36px,400,38px,#000,normal,none);}
  .uniTitBold {.uni(36px,700,38px,#000,normal,none);}
}
@media (max-width : 992px)
{
  .titulo {font-size:28px; line-height:32px;}
  .subtitulo {font-size:20px; line-height:24px;}
  .uniTitBook {.uni(28px,300,30px,#000,normal,none);}
  .uniTitRegular {.uni(28px,400,30px,#000,normal,none);}
  .uniTitBold {.uni(28px,700,30px,#000,normal,none);}
}
@media (max-width : 480px)
{
  .titulo {font-size:26px; line-height:30px; padding-bottom:20px;}
  .subtitulo {font-size:15px; line-height:18px;}
  .uniTitBook {.uni(26px,300,28px,#000,normal,none);}
  .uniTitRegular {.uni(26px,400,28px,#000,normal,none);}
  .uniTitBold {.uni(26px,700,28px,#000,normal,none);}
}

/* EFFECTS */

.transition(@time : 400ms, @animation : ease){
  -webkit-transition:all @time @animation; /* Safari */
  transition:all @time @animation;
}

.placeholder(@cor : rgba(0,0,0,0.5), @style : normal){
  ::-webkit-input-placeholder {color:@cor; opacity:1; font-style:@style}
  ::-moz-placeholder {color:@cor; opacity:1; font-style:@style}
  :-ms-input-placeholder {color:@cor; opacity:1; font-style:@style}
  :-moz-placeholder {color:@cor; opacity:1; font-style:@style}
}

.diagonal {position:absolute; height:2281px; background:rgba(0,0,0,0.03); width:1200px; z-index:0; bottom:0}

.dgn-left {
  left:0;
  -moz-transform: skewX(20.2deg) translateX(-56%);
  -webkit-transform: skewX(30deg) translateX(-56%);
  -o-transform: skewX(30deg) translateX(-56%);
  -ms-transform: skewX(30deg) translateX(-56%);
  transform: skewX(30deg) translateX(-56%);
}

.dgn-right {
  right:-78px;
  -moz-transform: skewX(-20.2deg) translateX(56%);
  -webkit-transform: skewX(-20.2deg) translateX(56%);
  -o-transform: skewX(-20.2deg) translateX(56%);
  -ms-transform: skewX(-20.2deg) translateX(56%);
  transform: skewX(-20.2deg) translateX(56%);
}
@media (max-width : 1440px)
{
  .dgn-right {right:-96px; height:1370px}
}
@media (max-width : 1024px)
{
  .dgn-right {right:-173px; height:1238px}
}
@media (max-width : 992px)
{
  .dgn-right {right:-206px; height:1164px}
}
@media (max-width : 740px)
{
  .dgn-left {display:none !important;}
  .dgn-right {display:none;}
}